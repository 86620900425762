import { Box, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, NumericDisplay } from '@ngt/forms';
import ValueWithSpecificationDisplay from '../../components/form/input/display/ValueWithSpecificationDisplay';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface ISurgicalPathologyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const SurgicalPathology: FunctionComponent<ISurgicalPathologyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedField
                    name="residualDiesease"
                    component={TextDisplay}
                />
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Tumour
                    </Typography>
                    <GroupedField
                        name="histoPathologicType"
                        component={ValueWithSpecificationDisplay}
                        specificationName="histoPathologicTypeSpecify"
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="histologicalGrade"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="lymphovascularInvasion"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="focalityCentricity"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="radialMarginsInvasive"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="radialMarginsInsitu"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="til"
                        component={NumericDisplay}
                        suffix={
                            <Typography component="span">%</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Residual Cancel Burden
                    </Typography>
                    <GroupedField
                        name="primaryTumourDimension1"
                        component={NumericDisplay}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="primaryTumourDimension2"
                        component={NumericDisplay}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="cancerCellularity"
                        component={NumericDisplay}
                        suffix={
                            <Typography component="span">%</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="percentageInsitu"
                        component={NumericDisplay}
                        suffix={
                            <Typography component="span">%</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="positiveLymphNodes"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="largestMetastasisDiameter"
                        component={NumericDisplay}
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                        }
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="residualCancerBurden"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="residualCancerBurdenClass"
                        component={NumericDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default SurgicalPathology;
