import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, OpenClinicaFormDisplay, OpmsSaeDisplay, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay, DateDisplay, BooleanDisplay } from '@ngt/forms';
import { AdverseEventItem, AdverseEvents as AdverseEventsDto, SeriousAdverseEventItem } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import TableTextResizer from '../../components/form/TableTextResizer';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { BasicAlert, useFullWidthLayout } from '@ngt/forms-trials';
import FormDates from '../../components/form/FormDates';


declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IAdverseEventsProps {
}


const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    table: {
        fontSize: `0.875rem !important`,
        background: 'inherit',

        '& .MuiTypography-root': {
            fontSize: `0.875rem !important`,
        },
        '& .MuiGrid-root': {
            background: 'inherit',
        }
    }
}));

const suspectDrugAccessor = () => "None";

const adverseEventColumns: IGroupedSubformGridCell<AdverseEventItem>[] = [
    {
        name: 'event',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.event
    },
    {
        name: 'systemsOrganClass',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.systemsOrganClass
    },
    {
        name: 'aeTerm',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="aeTermSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'aeTermSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.aeTerm ? `${row?.aeTerm} - ${row?.aeTermSpecify}` : undefined
    },
    {
        name: 'grade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.grade
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'resolution',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.resolution
    },
    {
        name: 'consequence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.consequence
    },
    {
        name: 'relationshipToTreatment',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.relationshipToTreatment
    },
    {
        name: 'actionTakenPaclitaxel',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenPaclitaxel
    },
    {
        name: 'actionTakenNivolumab',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenNivolumab
    },
    {
        name: 'actionTakenIpilimumab',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.actionTakenIpilimumab
    },
    {
        name: 'imGiven',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.imGiven
    },
    {
        name: 'treatmentGiven',
        content: (
            <Input
                component={BooleanDisplay}
            />
        ),
        sortValue: (row) => row?.treatmentGiven
    }
];

const saeColumns: IGroupedSubformGridCell<SeriousAdverseEventItem>[] = [
    {
        name: 'saeNumber',
        content: (
            <Input
                component={OpmsSaeDisplay}
                opmsUrl={opmsUrl}
                saeIdName="opmsSaeId"
                btnMinWidth="50px"
                saeIdPrependParentName
                saeIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.saeNumber
    },
    {
        name: 'term',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.term
    },
    {
        name: 'onset',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.onset
    },
    {
        name: 'outcome',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.outcome
    },
    {
        name: 'suspectDrugOrCause',
        content: (
            <Input
                component={IfElseDisplay}
                ifPrependParentName
                ifTableCell
                ifName="suspectDrugOrCause"
                elsePrependParentName
                elseTableCell
                elseAccessor={suspectDrugAccessor}
                elseName="suspectDrugOrCause"
            />
            
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'suspectDrugOrCause'
                }
            ]
        },
        sortValue: (row) => row?.suspectDrugOrCause ?? 'None'
    },
    {
        name: 'expectedness',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.expectedness
    },
    {
        name: 'comments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.comments
    }
];

const hasNoAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.adverseEventItems || value?.adverseEventItems?.length === 0;
const hasAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.adverseEventItems && value?.adverseEventItems?.length > 0;

const hasNoSeriousAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !value?.seriousAdverseEventItems || value?.seriousAdverseEventItems?.length === 0;
const hasSeriousAdverseEvents: ICondition<AdverseEventsDto> = ({ formState: { value } = {} }) => !!value?.seriousAdverseEventItems && value?.seriousAdverseEventItems?.length > 0;

const conditionSubscription = { value: true };

const AdverseEvents: FunctionComponent<IAdverseEventsProps> = () => {
    const { classes } = useStyles();

    useFullWidthLayout();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Adverse Event Listing
                    </Typography>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={AdverseEventItem}
                                name="adverseEventItems"
                                columns={adverseEventColumns}
                                disabled
                                stickHeader
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasNoAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Adverse Events"
                                description="There is no Adverse Events associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Serious Adverse Event Summary
                    </Typography>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasSeriousAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box className={classes.table}>
                            <GroupedSubformGrid
                                type={SeriousAdverseEventItem}
                                name="seriousAdverseEventItems"
                                columns={saeColumns}
                                disabled
                            />
                        </Box>
                    </Condition>
                    <Condition
                        type={AdverseEventsDto}
                        condition={hasNoSeriousAdverseEvents}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Serious Adverse Events"
                                description="There is no Serious Adverse Events associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default AdverseEvents;
