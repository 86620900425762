import { Theme, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, Input, NumericDisplay, SingleLineTextDisplay } from '@ngt/forms';
import { BreastUltrasound, Imaging as ImagingDto, Mammogram, PostAnthracylineMammogram } from '../../apis/dtos';
import { ConditionMode } from '@ngt/forms-core';
import ValueWithSpecificationDisplay from '../../components/form/input/display/ValueWithSpecificationDisplay';
import CoalesceDisplay from '../../components/form/input/display/CoalesceDisplay';
import { DateTime } from 'luxon';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface IImagingProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasBreastUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.breastUltrasoundNotDone;

const hasAxillaUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.axillaUltrasoundNotDone;

const hasCytologicalAssessment: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.cytologicalAssessment;

const hasMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.mammogramNotDone;



const hasPostBreastUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.postBreastUltrasoundNotDone;

const hasPostAxillaUltrasound: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.postAxillaUltrasoundNotDone;

const hasPostCytologicalAssessment: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.postCytologicalAssessment;

const hasPostMammogram: ICondition<ImagingDto> = ({ formState: { value } = {} }) => !value?.postMammogramNotDone;

const conditionSubscription = { value: true };

const breastUltrasoundColumns: IGroupedSubformGridCell<BreastUltrasound>[] = [
    {
        name: 'breastLesionNumber',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dimension1',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'dimension2',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    }
];

const mammogramColumns: IGroupedSubformGridCell<Mammogram>[] = [
    {
        name: 'breastLesionNumber',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'size',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    }
];

const dimension2NullAccessor = (value?: number) => value && value > 0 ? 'Not Done' : null;

const dimension2Accessor = (value?: number) => value || value === 0 ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mm</Typography></> : null;

const postAnthracylineMammogramColumns: IGroupedSubformGridCell<PostAnthracylineMammogram>[] = [
    {
        name: 'breastLesionNumber',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        width: 300,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dimension1',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'dimension2',
        content: (
            <Input
                component={CoalesceDisplay}
                ifNullName="dimension2NotDone"
                ifNullAccessor={dimension2NullAccessor}
                accessor={dimension2Accessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'dimension2NotDone'
                }
            ]
        }
    }
];

const dataAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const boolAccessor = (value: boolean) => value === true ? 'Not Done' : null;


const breastUltrasoundHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'breastUltrasoundDate'
        }
    ]
};

const axillaUltrasoundHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'axillaUltrasoundDate'
        }
    ]
};

const cytologicalAssessmentHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'cytologicalAssessmentDate'
        }
    ]
};

const findingsHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'findingsOther'
        }
    ]
};


const mammogramHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'dateOfMammogram'
        }
    ]
};


const postBreastUltrasoundHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'postBreastUltrasoundDate'
        }
    ]
};


const postAxillaUltrasoundHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'postAxillaUltrasoundDate'
        }
    ]
};


const postCytologicalAssessmentHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'postCytologicalAssessmentDate'
        }
    ]
};


const postFindingsHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'postFindingsOther'
        }
    ]
};


const postMammogramHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'postDateOfMammogram'
        }
    ]
};


const ctScanHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'ctScanDate'
        }
    ]
};


const boneScanHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'boneScanDate'
        }
    ]
};

const Imaging: FunctionComponent<IImagingProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Diagnostic
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Breast Ultrasound
                    </Typography>
                    <GroupedField
                        name="breastUltrasoundNotDone"
                        component={CoalesceDisplay}
                        ifNullName="breastUltrasoundDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={breastUltrasoundHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasBreastUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={BreastUltrasound}
                            name="breastUltrasounds"
                            columns={breastUltrasoundColumns}
                            disabled
                        />
                    </Condition>

                    <Typography variant="h3" className={classes.subtitle}>
                        Axilla Ultrasound
                    </Typography>
                    <GroupedField
                        name="axillaUltrasoundNotDone"
                        component={CoalesceDisplay}
                        ifNullName="axillaUltrasoundDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={axillaUltrasoundHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasAxillaUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="suspiciousAxillaryNodesIdentified"
                            component={SingleLineTextDisplay}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h3" className={classes.subtitle}>
                            Cytological Assessment
                        </Typography>
                        <GroupedField
                            name="cytologicalAssessment"
                            component={CoalesceDisplay}
                            ifNullName="cytologicalAssessmentDate"
                            ifNullAccessor={dataAccessor}
                            GroupStyleProps={groupStyleProps}
                            UpdatedFormPropertyStylerProps={cytologicalAssessmentHighlighting}
                        />
                        <Condition
                            type={ImagingDto}
                            condition={hasCytologicalAssessment}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="findings"
                                component={ValueWithSpecificationDisplay}
                                specificationName="findingsOther"
                                GroupStyleProps={groupStyleProps}
                                UpdatedFormPropertyStylerProps={findingsHighlighting}
                            />
                        </Condition>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Mammogram
                    </Typography>
                    <GroupedField
                        name="mammogramNotDone"
                        component={CoalesceDisplay}
                        ifNullName="dateOfMammogram"
                        ifNullAccessor={dataAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={mammogramHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >

                        <GroupedSubformGrid
                            type={Mammogram}
                            name="mammograms"
                            columns={mammogramColumns}
                            disabled
                        />
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Post - Anthracycline
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Breast Ultrasound
                    </Typography>
                    <GroupedField
                        name="postBreastUltrasoundNotDone"
                        component={CoalesceDisplay}
                        ifNullName="postBreastUltrasoundDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={postBreastUltrasoundHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasPostBreastUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={BreastUltrasound}
                            name="postAnthracylineBreastUltrasounds"
                            columns={breastUltrasoundColumns}
                            disabled
                        />
                    </Condition>

                    <Typography variant="h3" className={classes.subtitle}>
                        Axilla Ultrasound
                    </Typography>
                    <GroupedField
                        name="postAxillaUltrasoundNotDone"
                        component={CoalesceDisplay}
                        ifNullName="postAxillaUltrasoundDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={postAxillaUltrasoundHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasPostAxillaUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="postSuspiciousAxillaryNodesIdentified"
                            component={NumericDisplay}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> nodes</Typography>
                            }
                            GroupStyleProps={groupStyleProps}
                        />
                        <Typography variant="h3" className={classes.subtitle}>
                            Cytological Assessment
                        </Typography>
                        <GroupedField
                            name="postCytologicalAssessment"
                            component={CoalesceDisplay}
                            ifNullName="postCytologicalAssessmentDate"
                            ifNullAccessor={dataAccessor}
                            GroupStyleProps={groupStyleProps}
                            UpdatedFormPropertyStylerProps={postCytologicalAssessmentHighlighting}
                        />
                        <Condition
                            type={ImagingDto}
                            condition={hasPostCytologicalAssessment}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="postFindings"
                                component={ValueWithSpecificationDisplay}
                                specificationName="postFindingsOther"
                                GroupStyleProps={groupStyleProps}
                                UpdatedFormPropertyStylerProps={postFindingsHighlighting}
                            />
                        </Condition>
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Mammogram
                    </Typography>
                    <GroupedField
                        name="postMammogramNotDone"
                        component={CoalesceDisplay}
                        ifNullName="postDateOfMammogram"
                        ifNullAccessor={dataAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={postMammogramHighlighting}
                    />
                    <Condition
                        type={ImagingDto}
                        condition={hasPostMammogram}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >

                        <GroupedSubformGrid
                            type={Mammogram}
                            name="postAnthracylineMammograms"
                            columns={postAnthracylineMammogramColumns}
                            disabled
                        />
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        CT Scan
                    </Typography>
                    <GroupedField
                        name="ctScanNotDone"
                        component={CoalesceDisplay}
                        ifNullName="ctScanDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={ctScanHighlighting}
                    />
                    <Typography variant="h3" className={classes.subtitle}>
                        Bone Scan
                    </Typography>
                    <GroupedField
                        name="boneScanNotDone"
                        component={CoalesceDisplay}
                        ifNullName="boneScanDate"
                        ifNullAccessor={dataAccessor}
                        accessor={boolAccessor}
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={boneScanHighlighting}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Response Assessment
                    </Typography>
                    <GroupedField
                        name="assessmentIfClinicalResponse"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="surgicalOptions"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Imaging;
