import { Box, Theme, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { GroupedFields, TextDisplay, NumericDisplay, Input, FieldProvider, FormLabel, getParentPropertyPath } from '@ngt/forms';
import { AdjuvantTherapy, NeoadjuvantTherapy } from '../../apis/dtos';
import ExpectedDoseDisplay from '../../components/form/input/display/ExpectedDoseDisplay';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import ValueWithReasonAndSpecificationDisplay from '../../components/form/input/display/ValueWithReasonAndSpecificationDisplay';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { useFieldState, useScopedField } from '@ngt/forms-core';
import FormDates from '../../components/form/FormDates';

interface IChariotTherapyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    legend: {
        padding: theme.spacing(2),
        lineBreak: 'anywhere',
        '& span': {
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap'
        }
    },
    
}));

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = (value: string) => value ? <>Not Administered &ndash; {value}</> : 'Not Administered';
const booleanAccessor = (value: boolean) => value === true ? 'Yes' : value === false ? 'No' : null;

const neoadjuvantTherapyNivolumabColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'nivolumabAdministered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="nivolumabDateAdministered"
                elseName="nivolumabAdministeredSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabDateAdministered'
                },
                {
                    name: 'nivolumabAdministeredSpecify'
                }
            ]
        }
    },
    {
        name: 'nivolumabActualDose',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="nivolumab" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="nivolumab"
                component={ExpectedDoseDisplay}
                unitsName="nivolumabUnits"
                FieldProps={{
                    prependParentName:false
                }}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabUnits',
                    prependParentPath: false
                }
            ]
        }
    },
    {
        name: 'nivolumabLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="nivolumabLastCycleSpecify"
                reasonName="nivolumabLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabLastCycleSpecify'
                },
                {
                    name: 'nivolumabLastCycleReason'
                }
            ]
        }
    }
];

const paclitaxelDoseAccessor = (value: number) => value !== null && value !== undefined ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mg</Typography></> : null;

const neoadjuvantTherapyPaclitaxelColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'paclitaxelActualDoseDay1',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="paclitaxelActualDoseDay1Value"
                elseName="paclitaxelActualDoseDay1Specify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelActualDoseDay1Value'
                },
                {
                    name: 'paclitaxelActualDoseDay1Specify'
                }
            ]
        }
    },
    {
        name: 'paclitaxelActualDoseDay8',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="paclitaxelActualDoseDay8Value"
                elseName="paclitaxelActualDoseDay8Specify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelActualDoseDay8Value'
                },
                {
                    name: 'paclitaxelActualDoseDay8Specify'
                }
            ]
        }
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="paclitaxelDuBois" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="paclitaxelDuBois"
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
                FieldProps={{
                    prependParentName: false
                }}
            />
        )
    },
    {
        name: 'paclitaxelLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="paclitaxelLastCycleSpecify"
                reasonName="paclitaxelLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'paclitaxelLastCycleReason'
                },
                {
                    name: 'paclitaxelLastCycleSpecify'
                }
            ]
        }
    }
];

const ipilimumabSubscription = {
    
};

const ipilimumabParentSubscription = {
    value: true
};

const ipilimumabHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'ipilimumabUnits'
        }
    ]
}

const IpilimumabExpectedDose = () => {
    const { name } = useFieldState(ipilimumabSubscription);

    const parentName = getParentPropertyPath(name);

    const { state: { value: cycle } } = useScopedField(`${parentName}.cycle`, ipilimumabParentSubscription);

    if (cycle === 'Cycle One' || cycle === 'Cycle Four') {
        return (
            <InputOnlyField
                name="ipilimumab"
                component={ExpectedDoseDisplay}
                unitsName="ipilimumabUnits"
                FieldProps={{
                    prependParentName: false
                }}
                UpdatedFormPropertyStylerProps={ipilimumabHighlighting}
            />
        );
    }

    return (
        <Typography>
            0<Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
        </Typography>
    );

};

const neoadjuvantTherapyIpilimumabColumns: IGroupedSubformGridCell<NeoadjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'ipilimumabActualDose',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="ipilimumabActualDoseValue"
                elseName="ipilimumabActualDoseSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={paclitaxelDoseAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ipilimumabActualDoseValue'
                },
                {
                    name: 'ipilimumabActualDoseSpecify'
                }
            ]
        }
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="ipilimumab" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <IpilimumabExpectedDose />
        )
    },
    {
        name: 'ipilimumabLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="ipilimumabLastCycleSpecify"
                reasonName="ipilimumabLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ipilimumabLastCycleSpecify'
                },
                {
                    name: 'ipilimumabLastCycleReason'
                }
            ]
        }
    }
];

const adjuvantTherapyColumns: IGroupedSubformGridCell<AdjuvantTherapy>[] = [
    {
        name: 'cycle',
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'adjuvantAdministered',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="adjuvantDateAdministered"
                elseName="adjuvantAdministeredSpecify"
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'adjuvantDateAdministered'
                },
                {
                    name: 'adjuvantAdministeredSpecify'
                }
            ]
        }
    },
    {
        name: 'adjuvantActualDoseValue',
        width: 300,
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        )
    },
    {
        name: 'id',
        width: 300,
        header: (
            <FieldProvider name="nivolumabAdjuvant" prependParentName={false}>
                <FormLabel />
            </FieldProvider>
        ),
        content: (
            <InputOnlyField
                name="nivolumabAdjuvant"
                component={ExpectedDoseDisplay}
                unitsName="nivolumabUnits"
                FieldProps={{
                    prependParentName: false
                }}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'nivolumabUnits',
                    prependParentPath: false
                }
            ]
        }
    },
    {
        name: 'adjuvantLastCycle',
        width: 300,
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                specificationName="adjuvantLastCycleSpecify"
                reasonName="adjuvantLastCycleReason"
                reasonPrependParentName
                reasonTableCell
                specificationPrependParentName
                specificationTableCell
                accessor={booleanAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'adjuvantLastCycleSpecify'
                },
                {
                    name: 'adjuvantLastCycleReason'
                }
            ]
        }
    }
];

const ChariotTherapy: FunctionComponent<IChariotTherapyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Treatment Exposure Summary
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNacIpi">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureNacNivo">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposurePaclitaxel">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureSurgery">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <FieldProvider name="treatmentExposureANivo">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNacIpi"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureNacNivo"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposurePaclitaxel"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureSurgery"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="treatmentExposureANivo"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.legend}>
                        <Typography color="textSecondary" component="span">
                            BCS = Breast Conserving Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M = Mastectomy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            M+R = Mastectomy + Reconstruction;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            OS = Oncoplastic Surgery;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            SLNB = Sentinel Lymph Node Biopsy;
                        </Typography>
                        <Typography color="textSecondary" component="span">
                            S-SM = Skin Sparing Mastectomy;
                        </Typography>
                    </div>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Neoadjuvant Therapy
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Nivolumab
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyNivolumabColumns}
                        disabled
                    />
                    <Typography variant="h3" className={classes.subtitle}>
                        Paclitaxel
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyPaclitaxelColumns}
                        disabled
                    />
                    <Typography variant="h3" className={classes.subtitle}>
                        Ipilimumab
                    </Typography>
                    <GroupedSubformGrid
                        type={NeoadjuvantTherapy}
                        name="neoadjuvantTherapies"
                        columns={neoadjuvantTherapyIpilimumabColumns}
                        disabled
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Adjuvant Therapy
                    </Typography>
                    <Typography variant="h3" className={classes.subtitle}>
                        Nivolumab
                    </Typography>
                    <GroupedSubformGrid
                        type={AdjuvantTherapy}
                        name="adjuvantTherapies"
                        columns={adjuvantTherapyColumns}
                        disabled
                    />
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default ChariotTherapy;
