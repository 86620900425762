import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, SymbolDisplay, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedField, IUpdatedFormPropertyStyler } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, DateDisplay, TextDisplay } from '@ngt/forms';
import { BaselineHistopathology as BaselineHistopathologyDto } from '../../apis/dtos';
import { ConditionMode } from '@ngt/forms-core';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface IBaselineHistopathologyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));


const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const hasHistopathologicalTypeOther: ICondition<BaselineHistopathologyDto> = ({ formState: { value } = {} }) => !!value?.histopathologyTypeOther

const hasTilCount: ICondition<BaselineHistopathologyDto> = ({ formState: { value } = {} }) => !!value?.tilCountValue


const oestrogenHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'oestrogenSymbol'
        }
    ]
};

const progesteroneHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'progesteroneSymbol'
        }
    ]
};

const tilCountHighlighting: IUpdatedFormPropertyStyler = {
    additionaProperties: [
        {
            name: 'tilCountSymbol'
        }
    ]
};

const BaselineHistopathology: FunctionComponent<IBaselineHistopathologyProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedField
                    name="dateOfHistopathDignosis"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="primaryTumourSite"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedFields>
                    <Typography variant="h6" className={classes.subtitle}>
                        Clinical Staging at Diagnosis &ndash; pre Anthracycline
                    </Typography>
                    <GroupedField
                        name="clinicalStagingCT"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="clinicalStagingCN"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="clinicalStagingCM"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    </GroupedFields>
                <GroupedField
                    name="histopathologyType"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <Condition
                    type={BaselineHistopathologyDto}
                    condition={hasHistopathologicalTypeOther}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="histopathologyTypeOther"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </Condition>
                <GroupedField
                    name="histologicalGrade"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedField
                    name="stageAtDiagnosis"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                <GroupedFields>
                    <Typography variant="h6" className={classes.subtitle}>
                        Receptor Status
                    </Typography>
                    <GroupedField
                        name="oestrogenValue"
                        component={SymbolDisplay}
                        symbolName="oestrogenSymbol"
                        suffix="%"
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={oestrogenHighlighting}
                    />
                    <GroupedField
                        name="progesteroneValue"
                        component={SymbolDisplay}
                        symbolName="progesteroneSymbol"
                        suffix="%"
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={progesteroneHighlighting}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h6" className={classes.subtitle}>
                        HER2
                    </Typography>
                    <GroupedField
                        name="ihcScore"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="ishScore"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <Condition
                    type={BaselineHistopathologyDto}
                    condition={hasTilCount}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="tilCountValue"
                        component={SymbolDisplay}
                        symbolName="tilCountSymbol"
                        suffix="%"
                        GroupStyleProps={groupStyleProps}
                        UpdatedFormPropertyStylerProps={tilCountHighlighting}
                    />
                </Condition>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default BaselineHistopathology;
