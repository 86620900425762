import React, { FunctionComponent } from 'react';
import { getParentPropertyPath, IValidationError } from '@ngt/forms';
import { Typography } from '@mui/material';
import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';


interface IIfElseDisplayProps extends IInputRenderProps<string, IValidationError>
{
    condition?: (value: any) => boolean;
    ifName: string;
    ifAccessor: (value: any) => any;
    elseName: string;
    elseAccessor: (value: any) => any;
    ifPrependParentName?: boolean;
    ifTableCell?: boolean;
    elsePrependParentName?: boolean;
    elseTableCell?: boolean;
}

const subscription = { value: true };

const IfElseDisplay: FunctionComponent<IIfElseDisplayProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { ...restInputActions } },
    ifName,
    ifAccessor,
    elseName,
    elseAccessor,
    condition,
    ifPrependParentName,
    ifTableCell,
    elsePrependParentName,
    elseTableCell,
    ...rest
}) => {
    const parentContext = React.useContext(FieldContext);

    const computedIfName = React.useMemo(() => {
        if (parentContext?.name && ifPrependParentName === true) {
            const parentName = ifTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (ifName.startsWith('[')) {
                return `${parentName}${ifName}`;
            }

            return `${parentName}.${ifName}`;
        }

        return ifName;
    }, [parentContext?.name, ifName, ifPrependParentName, ifTableCell]);

    const computedElseName = React.useMemo(() => {
        if (parentContext?.name && elsePrependParentName === true) {
            const parentName = elseTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (elseName.startsWith('[')) {
                return `${parentName}${elseName}`;
            }

            return `${parentName}.${elseName}`;
        }

        return elseName;
    }, [parentContext?.name, elseName, elsePrependParentName, elseTableCell]);

    const { state: { value: ifValue } } = useScopedField<string, IValidationError>(computedIfName, subscription, false);
    const { state: { value: elseValue } } = useScopedField<string, IValidationError>(computedElseName, subscription, false);

    const conditionMet = condition ? condition(value) : !!value;

    const calculatedValue = conditionMet ?
        ifAccessor ? ifAccessor(ifValue) : ifValue :
        elseAccessor ? elseAccessor(elseValue) : elseValue;

    return (
        <Typography>
            {(calculatedValue)}
        </Typography>
    )
};

export default IfElseDisplay;
