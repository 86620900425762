/* Options:
Date: 2021-09-28 08:29:59
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44379

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export class AdjuvantTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public cycle: string;
    public adjuvantAdministered?: boolean;
    public adjuvantDateAdministered?: string;
    public adjuvantAdministeredSpecify: string;
    public adjuvantActualDose?: boolean;
    public adjuvantActualDoseValue?: number;
    public adjuvantLastCycle?: boolean;
    public adjuvantLastCycleReason: string;
    public adjuvantLastCycleSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdjuvantTherapy>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class AdverseEventItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public institution: string;
    public event: string;
    // @Ignore()
    public eventNumber?: number;

    public systemsOrganClass: string;
    public aeTermId?: number;
    public aeTerm: string;
    public aeTermSpecify: string;
    public grade?: number;
    public onset?: string;
    public resolution?: string;
    public consequence: string;
    public relationshipToTreatment: string;
    public actionTakenPaclitaxel: string;
    public actionTakenNivolumab: string;
    public actionTakenIpilimumab: string;
    public imGiven?: boolean;
    public treatmentGiven?: boolean;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEventItem>) { (Object as any).assign(this, init); }
}

export class Annotation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Annotation>) { (Object as any).assign(this, init); }
}

export class AntiCancerTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatment: string;
    public treatmentSpecify: string;
    public commencementDate?: string;
    public completedDate?: string;
    public ongoing?: boolean;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiCancerTreatment>) { (Object as any).assign(this, init); }
}

export class AntiCancerTreatment36Months implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatment: string;
    public treatmentSpecify: string;
    public commencementDate?: string;
    public completedDate?: string;
    public ongoing?: boolean;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AntiCancerTreatment36Months>) { (Object as any).assign(this, init); }
}

export class Biochemistry implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public studyEventId?: number;
    public cycle: string;
    public assessmentDone?: boolean;
    public date?: string;
    public creatinine?: boolean;
    public creatinineValue?: number;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public creatinineSign: string;
    public creatinineUnits: string;
    public creatinineUnitsSpecify: string;
    public egfr?: boolean;
    public egfrValue?: number;
    public egfrLln?: number;
    public egfrUln?: number;
    public egfrSign: string;
    public egfrUnits: string;
    public egfrUnitsSpecify: string;
    public asat?: boolean;
    public asatValue?: number;
    public asatLln?: number;
    public asatUln?: number;
    public asatSign: string;
    public asatUnits: string;
    public asatUnitsSpecify: string;
    public alat?: boolean;
    public alatValue?: number;
    public alatLln?: number;
    public alatUln?: number;
    public alatSign: string;
    public alatUnits: string;
    public alatUnitsSpecify: string;
    public ggt?: boolean;
    public ggtValue?: number;
    public ggtLln?: number;
    public ggtUln?: number;
    public ggtSign: string;
    public ggtUnits: string;
    public ggtUnitsSpecify: string;
    public ldh?: boolean;
    public ldhValue?: number;
    public ldhLln?: number;
    public ldhUln?: number;
    public ldhSign: string;
    public ldhUnits: string;
    public ldhUnitsSpecify: string;
    public alp?: boolean;
    public alpValue?: number;
    public alpLln?: number;
    public alpUln?: number;
    public alpSign: string;
    public alpUnits: string;
    public alpUnitsSpecify: string;
    public totalBilirubin?: boolean;
    public totalBilirubinValue?: number;
    public totalBilirubinLln?: number;
    public totalBilirubinUln?: number;
    public totalBilirubinSign: string;
    public totalBilirubinUnits: string;
    public totalBilirubinUnitsSpecify: string;
    public sodium?: boolean;
    public sodiumValue?: number;
    public sodiumLln?: number;
    public sodiumUln?: number;
    public sodiumSign: string;
    public sodiumUnits: string;
    public sodiumUnitsSpecify: string;
    public potassium?: boolean;
    public potassiumValue?: number;
    public potassiumLln?: number;
    public potassiumUln?: number;
    public potassiumSign: string;
    public potassiumUnits: string;
    public potassiumUnitsSpecify: string;
    public calcium?: boolean;
    public calciumValue?: number;
    public calciumLln?: number;
    public calciumUln?: number;
    public calciumSign: string;
    public calciumUnits: string;
    public calciumUnitsSpecify: string;
    public urea?: boolean;
    public ureaValue?: number;
    public ureaLln?: number;
    public ureaUln?: number;
    public ureaSign: string;
    public ureaUnits: string;
    public ureaUnitsSpecify: string;
    public amylase?: boolean;
    public amylaseValue?: number;
    public amylaseLln?: number;
    public amylaseUln?: number;
    public amylaseSign: string;
    public amylaseUnits: string;
    public amylaseUnitsSpecify: string;
    public lipase?: boolean;
    public lipaseValue?: number;
    public lipaseLln?: number;
    public lipaseUln?: number;
    public lipaseSign: string;
    public lipaseUnits: string;
    public lipaseUnitsSpecify: string;
    public cortisol?: boolean;
    public cortisolValue?: number;
    public cortisolLln?: number;
    public cortisolUln?: number;
    public cortisolSign: string;
    public cortisolUnits: string;
    public cortisolUnitsSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Biochemistry>) { (Object as any).assign(this, init); }
}

export class BreastCancerRecurrence implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public location: string;
    public dateSuspicion?: string;
    public dateConfirmation?: string;
    public timeOnStudy?: number;
    public typeOfConfirmation: string;
    public typeOfConfirmationSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastCancerRecurrence>) { (Object as any).assign(this, init); }
}

export class BreastUltrasound implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    public location: string;
    public dimension1?: number;
    public dimension2?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastUltrasound>) { (Object as any).assign(this, init); }
}

export class ClinicalTumourMeasurementCycle implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public cycleName: string;
    public clincalMeasurementsCompleted?: boolean;
    public assessmentCompleted?: boolean;
    public assessmentCompletedSpecify: string;
    public date?: string;
    public longestDiameter?: boolean;
    public longestDiameterValue?: number;
    public evidenceOfProgression: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycle>) { (Object as any).assign(this, init); }
}

export class ClinicalTumourMeasurement implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastMassNumber?: number;
    // @Ignore()
    public clinicalTumourMeasurementCycles: ClinicalTumourMeasurementCycle[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ClinicalTumourMeasurement>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public agentName: string;
    public dose: string;
    public units: string;
    public route: string;
    public indication: string;
    public dateStarted?: string;
    public dateStopped?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedicationItem>) { (Object as any).assign(this, init); }
}

export class DiagnosticTumourMeasurementStage implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public stage: string;
    public date?: string;
    public location: string;
    public dimension1?: number;
    public dimension2?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStage>) { (Object as any).assign(this, init); }
}

export class DiagnosticTumourMeasurement implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    // @Ignore()
    public diagnosticTumourMeasurementStages: DiagnosticTumourMeasurementStage[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DiagnosticTumourMeasurement>) { (Object as any).assign(this, init); }
}

export class Haematology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public studyEventId?: number;
    public cycle: string;
    public assessmentDone?: boolean;
    public date?: string;
    public haemoglobinNotDone?: boolean;
    public haemoglobinValue?: number;
    public haemoglobinLln?: number;
    public haemoglobinUln?: number;
    public haemoglobinSign: string;
    public haemoglobinUnits: string;
    public haemoglobinUnitsSpecify: string;
    public neutrophilsNotDone?: boolean;
    public neutrophilsValue?: number;
    public neutrophilsLln?: number;
    public neutrophilsUln?: number;
    public neutrophilsSign: string;
    public neutrophilsUnits: string;
    public neutrophilsUnitsSpecify: string;
    public plateletsNotDone?: boolean;
    public plateletsValue?: number;
    public plateletsLln?: number;
    public plateletsUln?: number;
    public plateletsSign: string;
    public plateletsUnits: string;
    public plateletsUnitsSpecify: string;
    public wbcNotDone?: boolean;
    public wbcValue?: number;
    public wbcLln?: number;
    public wbcUln?: number;
    public wbcSign: string;
    public wbcUnits: string;
    public wbcUnitsSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Haematology>) { (Object as any).assign(this, init); }
}

export class Mammogram implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    public location: string;
    public size?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Mammogram>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicalCondition: string;
    public currentAtRegistration: string;
    public dateOfDiagnosis: string;
    public treatmentOngoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryItem>) { (Object as any).assign(this, init); }
}

export class NeoadjuvantTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public cycle: string;
    public nivolumabAdministered?: boolean;
    public nivolumabDateAdministered?: string;
    public nivolumabAdministeredSpecify: string;
    public nivolumabActualDose?: number;
    public nivolumabUnits: string;
    public nivolumabLastCycle?: boolean;
    public nivolumabLastCycleReason: string;
    public nivolumabLastCycleSpecify: string;
    public paclitaxelUnits: string;
    public paclitaxelActualDoseDay1?: boolean;
    public paclitaxelActualDoseDay1Value?: number;
    public paclitaxelActualDoseDay1Specify: string;
    public paclitaxelActualDoseDay8?: boolean;
    public paclitaxelActualDoseDay8Value?: number;
    public paclitaxelActualDoseDay8Specify: string;
    public paclitaxelLastCycle?: boolean;
    public paclitaxelLastCycleReason: string;
    public paclitaxelLastCycleSpecify: string;
    public ipilimumabActualDose?: boolean;
    public ipilimumabActualDoseValue?: number;
    public ipilimumabActualDoseSpecify: string;
    public ipilimumabLastCycle?: boolean;
    public ipilimumabLastCycleReason: string;
    public ipilimumabLastCycleSpecify: string;
    public ipilimumabUnits: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NeoadjuvantTherapy>) { (Object as any).assign(this, init); }
}

export class PostAnthracylineBreastUltrasound implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    public location: string;
    public dimension1?: number;
    public dimension2?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasound>) { (Object as any).assign(this, init); }
}

export class PostAnthracylineMammogram implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    public location: string;
    public dimension1?: number;
    public dimension2NotDone?: boolean;
    public dimension2?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PostAnthracylineMammogram>) { (Object as any).assign(this, init); }
}

export class ProtocolDeviation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProtocolDeviation>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEventItem implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public opmsSaeId?: number;
    public saeNumber?: number;
    public age?: number;
    public term: string;
    public onset?: string;
    public outcome?: string;
    public suspectDrugOrCause: string;
    public expectedness: string;
    public dose: string;
    public datesOfTreatment: string;
    public comments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEventItem>) { (Object as any).assign(this, init); }
}

export class SlnbResult implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public nodeNumber?: number;
    public location: string;
    public hot?: boolean;
    public blue?: boolean;
    public tumourInvolvement: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SlnbResult>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public section: string;
    public type: string;
    public name: string;
    public path: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SurgeryBreastUltrasound implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastLesionNumber: string;
    public location: string;
    public dimension1?: number;
    public dimension2?: number;
    public response: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgeryBreastUltrasound>) { (Object as any).assign(this, init); }
}

export class SurgeryType implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public date?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgeryType>) { (Object as any).assign(this, init); }
}

export class ThyroidFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public studyEventId?: number;
    public cycle: string;
    public assessmentDone?: boolean;
    public date?: string;
    public tsh?: boolean;
    public tshValue?: number;
    public tshLln?: number;
    public tshUln?: number;
    public tshSign: string;
    public tshUnits: string;
    public tshUnitsSpecify: string;
    public t3?: boolean;
    public t3Value?: number;
    public t3Lln?: number;
    public t3Uln?: number;
    public t3Sign: string;
    public t3Units: string;
    public t3UnitsSpecify: string;
    public t4?: boolean;
    public t4Value?: number;
    public t4Lln?: number;
    public t4Uln?: number;
    public t4Sign: string;
    public t4Units: string;
    public t4UnitsSpecify: string;
    public acth?: boolean;
    public acthValue?: number;
    public acthLln?: number;
    public acthUln?: number;
    public acthSign: string;
    public acthUnits: string;
    public acthUnitsSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ThyroidFunction>) { (Object as any).assign(this, init); }
}

export class Urinalysis implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public studyEventId?: number;
    public cycle: string;
    public urinalysisDone?: boolean;
    public date?: string;
    public result: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Urinalysis>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public medicalReviewId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class AdverseEvents implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public adverseEventItems: AdverseEventItem[];

    // @Ignore()
    public seriousAdverseEventItems: SeriousAdverseEventItem[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvents>) { (Object as any).assign(this, init); }
}

export class BaselineHistopathology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfHistopathDignosis?: string;
    public primaryTumourSite: string;
    public clinicalStagingCT: string;
    public clinicalStagingCN: string;
    public clinicalStagingCM: string;
    public histopathologyType: string;
    public histopathologyTypeOther: string;
    public histologicalGrade: string;
    public stageAtDiagnosis: string;
    public oestrogenSymbol: string;
    public oestrogenValue?: number;
    public progesteroneSymbol: string;
    public progesteroneValue?: number;
    public ihcScore: string;
    public ishScore: string;
    public tilCountSymbol: string;
    public tilCountValue?: number;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BaselineHistopathology>) { (Object as any).assign(this, init); }
}

export class ChariotTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatmentExposureNacIpi?: number;
    public treatmentExposureNacNivo?: number;
    public treatmentExposurePaclitaxel?: number;
    public treatmentExposureSurgery: string;
    public treatmentExposureANivo?: number;
    public nivolumab?: number;
    public nivolumabAdjuvant?: number;
    public nivolumabUnits: string;
    public paclitaxelMosteller?: number;
    public paclitaxelMostellerUnits: string;
    public paclitaxelDuBois?: number;
    public paclitaxelDuBoisUnits: string;
    public ipilimumab?: number;
    public ipilimumabUnits: string;
    // @Ignore()
    public neoadjuvantTherapies: NeoadjuvantTherapy[];

    // @Ignore()
    public adjuvantTherapies: AdjuvantTherapy[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ChariotTherapy>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedications implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public concomitantMedicationItems: ConcomitantMedicationItem[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedications>) { (Object as any).assign(this, init); }
}

export class GeneralInformation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waivered?: boolean;
    public waiverDate?: string;
    public waiverReason: string;
    // @Ignore()
    public protocolDeviations: ProtocolDeviation[];

    public comments: string;
    public inevaluable?: boolean;
    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralInformation>) { (Object as any).assign(this, init); }
}

export class Imaging implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastUltrasoundNotDone?: boolean;
    public breastUltrasoundDate?: string;
    // @Ignore()
    public breastUltrasounds: BreastUltrasound[];

    public axillaUltrasoundNotDone?: boolean;
    public axillaUltrasoundDate?: string;
    public suspiciousAxillaryNodesIdentified?: number;
    public cytologicalAssessment: string;
    public cytologicalAssessmentDate?: string;
    public findings: string;
    public findingsOther: string;
    public mammogramNotDone: string;
    public dateOfMammogram?: string;
    // @Ignore()
    public mammograms: Mammogram[];

    public postBreastUltrasoundNotDone?: boolean;
    public postBreastUltrasoundDate?: string;
    // @Ignore()
    public postAnthracylineBreastUltrasounds: PostAnthracylineBreastUltrasound[];

    public postAxillaUltrasoundNotDone?: boolean;
    public postAxillaUltrasoundDate?: string;
    public postSuspiciousAxillaryNodesIdentified?: number;
    public postCytologicalAssessment: string;
    public postCytologicalAssessmentDate?: string;
    public postFindings: string;
    public postFindingsOther: string;
    public postMammogramNotDone: string;
    public postDateOfMammogram?: string;
    // @Ignore()
    public postAnthracylineMammograms: PostAnthracylineMammogram[];

    public ctScanNotDone?: boolean;
    public ctScanDate?: string;
    public boneScanNotDone?: boolean;
    public boneScanDate?: string;
    public assessmentIfClinicalResponse: string;
    public surgicalOptions: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Imaging>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public medicalHistoryItems: MedicalHistoryItem[];

    public chemotherapyAdministeredType: string;
    public chemotherapyAdministeredTypeOther: string;
    public dateOfCommencement?: string;
    public dateOfCompletion?: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class OrganFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfBloodTest?: string;
    public haemoglobin?: number;
    public neutrophils?: number;
    public platelets?: number;
    public wbcLeukocyteCount?: number;
    public totalBilirubin?: number;
    public totalBilirubinUln?: number;
    public asatSgot?: number;
    public asatSgotUln?: number;
    public alatSgpt?: number;
    public alatSgptUln?: number;
    public alkalinePhosphatase?: number;
    public alkalinePhosphataseUln?: number;
    public creatinine?: number;
    public creatinineLln?: number;
    public creatinineUln?: number;
    public calculatedCreatinineClearance?: number;
    public calcium?: number;
    public ldh?: number;
    public totalProtein?: number;
    public serumUrea?: number;
    public albumin?: number;
    public tsh?: number;
    public t3?: number;
    public t4?: number;
    public adrenocorticotropicHormone?: number;
    public amylase?: number;
    public lipase?: number;
    public cortisol?: number;
    public bloodGlucoseLevel?: number;
    public ecog: string;
    public ecg?: boolean;
    public ecgSpec?: number;
    public mugaOrEcho?: boolean;
    public mugaOrEchoValue?: number;
    public mugaOrEchoLln?: number;
    public mugaOrEchoAssessmentDate?: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunction>) { (Object as any).assign(this, init); }
}

export class OrganFunctionTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public haematologyCycles: Haematology[];

    // @Ignore()
    public biochemistryCycles: Biochemistry[];

    // @Ignore()
    public thyroidFunctionCycles: ThyroidFunction[];

    // @Ignore()
    public urinalysisCycles: Urinalysis[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OrganFunctionTreatment>) { (Object as any).assign(this, init); }
}

export class RecurrenceSurvival implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public followUpDate24Month?: string;
    public changes24Month: string;
    // @Ignore()
    public antiCancerTreatments24Month: AntiCancerTreatment[];

    public chemoAdministered24Month: string;
    public chemoAdministeredSpecify24Month: string;
    public commencementDate24Month?: string;
    public completedDate24Month?: string;
    public followUpDate36Month?: string;
    public changes36Month: string;
    // @Ignore()
    public antiCancerTreatments36Month: AntiCancerTreatment36Months[];

    public chemoAdministered36Month: string;
    public chemoAdministeredSpecify36Month: string;
    public commencementDate36Month?: string;
    public completedDate36Month?: string;
    // @Ignore()
    public breastCancerRecurrences: BreastCancerRecurrence[];

    public dateOfDeath?: string;
    public timeOnStudy?: number;
    public reasonOfDeath: string;
    public reasonOfDeathSpecify: string;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<RecurrenceSurvival>) { (Object as any).assign(this, init); }
}

export class Surgery implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastUltrasound?: boolean;
    public breastUltrasoundDate?: string;
    public breastUltrasoundSpecify: string;
    // @Ignore()
    public surgeryBreastUltrasounds: SurgeryBreastUltrasound[];

    public suspiciousAxillaryNodes?: boolean;
    public number?: number;
    public surgicalAssessment?: boolean;
    public surgicalAssessmentDate?: string;
    public surgicalAssessmentSpecify: string;
    public surgeryRequired?: boolean;
    public surgeryCompleted?: boolean;
    // @Ignore()
    public surgeryTypes: SurgeryType[];

    public slnb?: boolean;
    public slnbDate?: string;
    public slnbRemoved?: number;
    public nonSlnbRemoved?: number;
    public injectionType: string;
    // @Ignore()
    public slnbResults: SlnbResult[];

    public axillaryDissectionPerformed?: boolean;
    public axillaryDissectionPerformedDate?: string;
    public nodesRemoved?: number;
    public positiveNodes?: number;
    public axillaryRTPlanned?: boolean;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Surgery>) { (Object as any).assign(this, init); }
}

export class SurgicalPathology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public residualDiesease: string;
    public histoPathologicType: string;
    public histoPathologicTypeSpecify: string;
    public histologicalGrade: string;
    public lymphovascularInvasion: string;
    public focalityCentricity: string;
    public radialMarginsInvasive: string;
    public radialMarginsInsitu: string;
    public til?: number;
    public primaryTumourDimension1?: number;
    public primaryTumourDimension2?: number;
    public cancerCellularity?: number;
    public percentageInsitu?: number;
    public positiveLymphNodes?: number;
    public largestMetastasisDiameter?: number;
    public residualCancerBurden?: number;
    public residualCancerBurdenClass?: number;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgicalPathology>) { (Object as any).assign(this, init); }
}

export class TumourMeasurements implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public clinicalTumourMeasurements: ClinicalTumourMeasurement[];

    // @Ignore()
    public diagnosticTumourMeasurements: DiagnosticTumourMeasurement[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TumourMeasurements>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class MedicalReviewPatient implements IMedicalReviewPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public medicalReviewers: number[];

    // @Ignore()
    public requiresAttention: number[];

    // @Ignore()
    public reviewStatus?: MedicalReviewStatus;

    public constructor(init?: Partial<MedicalReviewPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export class Reviewer implements IReviewer
{
    public id?: number;
    public onTrial?: boolean;
    public masterGroups: { [index: number]: boolean; };
    public coordinatingGroups: { [index: number]: boolean; };
    public institutions: { [index: number]: boolean; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Reviewer>) { (Object as any).assign(this, init); }
}

export class MedicalReview implements IMedicalReview
{
    public id?: number;
    public patientId?: number;
    public medicalReviewDefinitionId?: number;
    public status?: MedicalReviewStatus;
    public completeDate?: string;
    public cancelDate?: string;
    public reviewerId?: number;
    public repeat?: number;
    public formDefinitionIds: number[];
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class MedicalReviewDefinition implements IMedicalReviewDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<MedicalReviewDefinition>) { (Object as any).assign(this, init); }
}

export class QueryRecipient implements IQueryRecipient
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<QueryRecipient>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    completeDate?: string;
    cancelDate?: string;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IHasCode
{
    code: string;
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class SendMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: MedicalReviewPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user.
    */
    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adjuvant therapys.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy[];

    public constructor(init?: Partial<AdjuvantTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdjuvantTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event item.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event items.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem[];

    public constructor(init?: Partial<AdverseEventItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved annotation.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved annotations.
    */
    // @DataMember(Order=2)
    public data: Annotation[];

    public constructor(init?: Partial<AnnotationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AnnotationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatments.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment[];

    public constructor(init?: Partial<AntiCancerTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiCancerTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment36 months.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Months;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved anti cancer treatment36 monthss.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Months[];

    public constructor(init?: Partial<AntiCancerTreatment36MonthsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AntiCancerTreatment36MonthsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved biochemistry.
    */
    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved biochemistrys.
    */
    // @DataMember(Order=2)
    public data: Biochemistry[];

    public constructor(init?: Partial<BiochemistryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiochemistryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BiochemistryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast cancer recurrences.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence[];

    public constructor(init?: Partial<BreastCancerRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastCancerRecurrenceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastCancerRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast ultrasounds.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound[];

    public constructor(init?: Partial<BreastUltrasoundCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastUltrasoundValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastUltrasoundValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurement.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurements.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement[];

    public constructor(init?: Partial<ClinicalTumourMeasurementCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ClinicalTumourMeasurementValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementCycleSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurement cycle.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurementCycle;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementCycleCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical tumour measurement cycles.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurementCycle[];

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalTumourMeasurementCycleValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication item.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication items.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem[];

    public constructor(init?: Partial<ConcomitantMedicationItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic tumour measurement.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurement;

    public constructor(init?: Partial<DiagnosticTumourMeasurementSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic tumour measurements.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurement[];

    public constructor(init?: Partial<DiagnosticTumourMeasurementCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DiagnosticTumourMeasurementValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementStageSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic tumour measurement stage.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurementStage;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementStageCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic tumour measurement stages.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurementStage[];

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticTumourMeasurementStageValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved haematology.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved haematologys.
    */
    // @DataMember(Order=2)
    public data: Haematology[];

    public constructor(init?: Partial<HaematologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HaematologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HaematologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MammogramSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved mammogram.
    */
    // @DataMember(Order=2)
    public data: Mammogram;

    public constructor(init?: Partial<MammogramSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MammogramCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved mammograms.
    */
    // @DataMember(Order=2)
    public data: Mammogram[];

    public constructor(init?: Partial<MammogramCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MammogramValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MammogramValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history item.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history items.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem[];

    public constructor(init?: Partial<MedicalHistoryItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neoadjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved neoadjuvant therapys.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy[];

    public constructor(init?: Partial<NeoadjuvantTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NeoadjuvantTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NeoadjuvantTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineBreastUltrasoundSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved post anthracyline breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineBreastUltrasound;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineBreastUltrasoundCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved post anthracyline breast ultrasounds.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineBreastUltrasound[];

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineBreastUltrasoundValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineMammogramSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved post anthracyline mammogram.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineMammogram;

    public constructor(init?: Partial<PostAnthracylineMammogramSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineMammogramCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved post anthracyline mammograms.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineMammogram[];

    public constructor(init?: Partial<PostAnthracylineMammogramCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PostAnthracylineMammogramValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PostAnthracylineMammogramValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved protocol deviations.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation[];

    public constructor(init?: Partial<ProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event item.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved serious adverse event items.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem[];

    public constructor(init?: Partial<SeriousAdverseEventItemCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventItemValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventItemValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved slnb results.
    */
    // @DataMember(Order=2)
    public data: SlnbResult[];

    public constructor(init?: Partial<SlnbResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SlnbResultValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SlnbResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public data: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryBreastUltrasoundSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: SurgeryBreastUltrasound;

    public constructor(init?: Partial<SurgeryBreastUltrasoundSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryBreastUltrasoundCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery breast ultrasounds.
    */
    // @DataMember(Order=2)
    public data: SurgeryBreastUltrasound[];

    public constructor(init?: Partial<SurgeryBreastUltrasoundCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryBreastUltrasoundValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryBreastUltrasoundValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery type.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery types.
    */
    // @DataMember(Order=2)
    public data: SurgeryType[];

    public constructor(init?: Partial<SurgeryTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryTypeValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved thyroid function.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved thyroid functions.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction[];

    public constructor(init?: Partial<ThyroidFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ThyroidFunctionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ThyroidFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved urinalysis.
    */
    // @DataMember(Order=2)
    public data: Urinalysis;

    public constructor(init?: Partial<UrinalysisSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved urinalysiss.
    */
    // @DataMember(Order=2)
    public data: Urinalysis[];

    public constructor(init?: Partial<UrinalysisCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UrinalysisValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse eventss.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents[];

    public constructor(init?: Partial<AdverseEventsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdverseEventsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineHistopathologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved baseline histopathology.
    */
    // @DataMember(Order=2)
    public data: BaselineHistopathology;

    public constructor(init?: Partial<BaselineHistopathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineHistopathologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved baseline histopathologys.
    */
    // @DataMember(Order=2)
    public data: BaselineHistopathology[];

    public constructor(init?: Partial<BaselineHistopathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineHistopathologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BaselineHistopathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChariotTherapySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved chariot therapy.
    */
    // @DataMember(Order=2)
    public data: ChariotTherapy;

    public constructor(init?: Partial<ChariotTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChariotTherapyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved chariot therapys.
    */
    // @DataMember(Order=2)
    public data: ChariotTherapy[];

    public constructor(init?: Partial<ChariotTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChariotTherapyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ChariotTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medicationss.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications[];

    public constructor(init?: Partial<ConcomitantMedicationsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general information.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general informations.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation[];

    public constructor(init?: Partial<GeneralInformationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralInformationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved imaging.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved imagings.
    */
    // @DataMember(Order=2)
    public data: Imaging[];

    public constructor(init?: Partial<ImagingCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ImagingValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ functions.
    */
    // @DataMember(Order=2)
    public data: OrganFunction[];

    public constructor(init?: Partial<OrganFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function treatment.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved organ function treatments.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment[];

    public constructor(init?: Partial<OrganFunctionTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OrganFunctionTreatmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OrganFunctionTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RecurrenceSurvivalSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved recurrence survival.
    */
    // @DataMember(Order=2)
    public data: RecurrenceSurvival;

    public constructor(init?: Partial<RecurrenceSurvivalSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RecurrenceSurvivalCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved recurrence survivals.
    */
    // @DataMember(Order=2)
    public data: RecurrenceSurvival[];

    public constructor(init?: Partial<RecurrenceSurvivalCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RecurrenceSurvivalValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<RecurrenceSurvivalValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgerys.
    */
    // @DataMember(Order=2)
    public data: Surgery[];

    public constructor(init?: Partial<SurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalPathologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgical pathology.
    */
    // @DataMember(Order=2)
    public data: SurgicalPathology;

    public constructor(init?: Partial<SurgicalPathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalPathologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgical pathologys.
    */
    // @DataMember(Order=2)
    public data: SurgicalPathology[];

    public constructor(init?: Partial<SurgicalPathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalPathologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgicalPathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourMeasurementsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved tumour measurements.
    */
    // @DataMember(Order=2)
    public data: TumourMeasurements;

    public constructor(init?: Partial<TumourMeasurementsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourMeasurementsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved tumour measurementss.
    */
    // @DataMember(Order=2)
    public data: TumourMeasurements[];

    public constructor(init?: Partial<TumourMeasurementsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourMeasurementsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TumourMeasurementsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating groups.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review definition.
    */
    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review definitions.
    */
    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query recipient.
    */
    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query recipients.
    */
    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved reviewer.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved reviewers.
    */
    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical reviews.
    */
    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    /**
    * If no forms were provided for medical review creation.
    */
    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    /**
    * If no reports had changes during the medical review creation.
    */
    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved querys.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved updatedformpropertys.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

export class QueueAvailableMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailableMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueAvailableMedicalReviewEmails'; }
}

export class SendMedicalReviewsAvailableEmails implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendMedicalReviewsAvailableEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendMedicalReviewsAvailableEmails'; }
}

export class SendMedicalReviewCompleteEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public medicalReviewId?: number;

    public constructor(init?: Partial<SendMedicalReviewCompleteEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendMedicalReviewCompleteEmail'; }
}

export class SendNewQueryAddedEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAddedEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendNewQueryAddedEmail'; }
}

export class SendNewQueryAsResponseEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendNewQueryAsResponseEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendNewQueryAsResponseEmail'; }
}

export class SendQueryClosedEarlyEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryClosedEarlyEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendQueryClosedEarlyEmail'; }
}

export class SendQueryResponseEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public queryId?: number;

    public constructor(init?: Partial<SendQueryResponseEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendQueryResponseEmail'; }
}

// @Route("/medical-review/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
}

// @Route("/emails/reviewer-query-summary/{ReviewerId}")
export class SendReviewerQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendReviewerQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
    public getTypeName() { return 'SendReviewerQuerySummaryEmail'; }
}

export class QueueReviewerQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueReviewerQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueueEmailJobsResponse(); }
    public getTypeName() { return 'QueueReviewerQuerySummaryEmails'; }
}

// @Route("/api/report/queries")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'PostUploadFile'; }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'GetUploadInfo'; }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetUpload'; }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadUpload'; }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'DeleteUpload'; }
}

/**
* User
*/
// @Route("/medical-review/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserSingleResponse(); }
    public getTypeName() { return 'UserGetSingle'; }
}

/**
* Lookup
*/
// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupCollectionResponse(); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
}

// @Route("/medical-review/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormMetadataResponse(); }
    public getTypeName() { return 'GetFormMetadata'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeCollectionResponse(); }
    public getTypeName() { return 'FormTypeGetCollection'; }
}

/**
* Form Type
*/
// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
}

/**
* Form Definition
*/
// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetSingleById implements IReturn<AdjuvantTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adjuvant therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetSingleById'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/collection", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetCollection implements IReturn<AdjuvantTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapyCollectionResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetCollection'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/create", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostCreate implements IReturn<AdjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostCreate'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/save", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostSave implements IReturn<AdjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adjuvant therapy to save.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostSave'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/validate", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostValidate implements IReturn<AdjuvantTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the adjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adjuvant therapy to validate.
    */
    // @DataMember(Order=2)
    public data: AdjuvantTherapy;

    public constructor(init?: Partial<AdjuvantTherapyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapyValidationResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostValidate'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdjuvantTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the adjuvant therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdjuvantTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - AdjuvantTherapy
*/
// @Route("/medical-review/form/adjuvant-therapy/single/update", "POST")
// @Api(Description="Form - AdjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantTherapyPostUpdateById implements IReturn<AdjuvantTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdjuvantTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdjuvantTherapySingleResponse(); }
    public getTypeName() { return 'AdjuvantTherapyPostUpdateById'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetSingleById implements IReturn<AdverseEventItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse event item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
    public getTypeName() { return 'AdverseEventItemGetSingleById'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/collection", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetCollection implements IReturn<AdverseEventItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemCollectionResponse(); }
    public getTypeName() { return 'AdverseEventItemGetCollection'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/create", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostCreate implements IReturn<AdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse event item.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
    public getTypeName() { return 'AdverseEventItemPostCreate'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/save", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostSave implements IReturn<AdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event item to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
    public getTypeName() { return 'AdverseEventItemPostSave'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/validate", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostValidate implements IReturn<AdverseEventItemValidationResponse>
{
    /**
    * The metadata to use when validating the adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse event item to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEventItem;

    public constructor(init?: Partial<AdverseEventItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemValidationResponse(); }
    public getTypeName() { return 'AdverseEventItemPostValidate'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the adverse event item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
    public getTypeName() { return 'AdverseEventItemGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - AdverseEventItem
*/
// @Route("/medical-review/form/adverse-event-item/single/update", "POST")
// @Api(Description="Form - AdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventItemPostUpdateById implements IReturn<AdverseEventItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventItemPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventItemSingleResponse(); }
    public getTypeName() { return 'AdverseEventItemPostUpdateById'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/id/{Id}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleById implements IReturn<AnnotationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the annotation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AnnotationGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationSingleResponse(); }
    public getTypeName() { return 'AnnotationGetSingleById'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/collection", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetCollection implements IReturn<AnnotationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AnnotationGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationCollectionResponse(); }
    public getTypeName() { return 'AnnotationGetCollection'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/create", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostCreate implements IReturn<AnnotationSingleResponse>
{
    /**
    * The metadata to use when creating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the annotation.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationSingleResponse(); }
    public getTypeName() { return 'AnnotationPostCreate'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/save", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostSave implements IReturn<AnnotationSingleResponse>
{
    /**
    * The metadata to use when creating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The annotation to save.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationSingleResponse(); }
    public getTypeName() { return 'AnnotationPostSave'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/validate", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostValidate implements IReturn<AnnotationValidationResponse>
{
    /**
    * The metadata to use when validating the annotation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The annotation to validate.
    */
    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationValidationResponse(); }
    public getTypeName() { return 'AnnotationPostValidate'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleByMedicalReviewIdAndRepeat implements IReturn<AnnotationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the annotation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AnnotationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationSingleResponse(); }
    public getTypeName() { return 'AnnotationGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Annotation
*/
// @Route("/medical-review/form/annotation/single/update", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostUpdateById implements IReturn<AnnotationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AnnotationPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnnotationSingleResponse(); }
    public getTypeName() { return 'AnnotationPostUpdateById'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetSingleById implements IReturn<AntiCancerTreatmentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the anti cancer treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetSingleById'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/collection", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetCollection implements IReturn<AntiCancerTreatmentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentCollectionResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetCollection'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/create", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostCreate implements IReturn<AntiCancerTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the anti cancer treatment.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostCreate'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/save", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostSave implements IReturn<AntiCancerTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment to save.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostSave'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/validate", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostValidate implements IReturn<AntiCancerTreatmentValidationResponse>
{
    /**
    * The metadata to use when validating the anti cancer treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment to validate.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment;

    public constructor(init?: Partial<AntiCancerTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentValidationResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostValidate'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<AntiCancerTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the anti cancer treatment to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiCancerTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - AntiCancerTreatment
*/
// @Route("/medical-review/form/anti-cancer-treatment/single/update", "POST")
// @Api(Description="Form - AntiCancerTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatmentPostUpdateById implements IReturn<AntiCancerTreatmentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiCancerTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatmentSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatmentPostUpdateById'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/single/id/{Id}", "GET")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsGetSingleById implements IReturn<AntiCancerTreatment36MonthsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the anti cancer treatment36 months to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsGetSingleById'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/collection", "GET")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsCollectionResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsGetCollection implements IReturn<AntiCancerTreatment36MonthsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsCollectionResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsGetCollection'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/create", "POST")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsPostCreate implements IReturn<AntiCancerTreatment36MonthsSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment36 months.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the anti cancer treatment36 months.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Months;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsPostCreate'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/save", "POST")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsPostSave implements IReturn<AntiCancerTreatment36MonthsSingleResponse>
{
    /**
    * The metadata to use when creating the anti cancer treatment36 months.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment36 months to save.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Months;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsPostSave'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/validate", "POST")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsValidationResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsPostValidate implements IReturn<AntiCancerTreatment36MonthsValidationResponse>
{
    /**
    * The metadata to use when validating the anti cancer treatment36 months.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The anti cancer treatment36 months to validate.
    */
    // @DataMember(Order=2)
    public data: AntiCancerTreatment36Months;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsValidationResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsPostValidate'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsGetSingleByMedicalReviewIdAndRepeat implements IReturn<AntiCancerTreatment36MonthsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the anti cancer treatment36 months to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AntiCancerTreatment36MonthsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - AntiCancerTreatment36Months
*/
// @Route("/medical-review/form/anti-cancer-treatment36-months/single/update", "POST")
// @Api(Description="Form - AntiCancerTreatment36Months")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AntiCancerTreatment36MonthsSingleResponse)", StatusCode=500)
// @DataContract
export class AntiCancerTreatment36MonthsPostUpdateById implements IReturn<AntiCancerTreatment36MonthsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AntiCancerTreatment36MonthsPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AntiCancerTreatment36MonthsSingleResponse(); }
    public getTypeName() { return 'AntiCancerTreatment36MonthsPostUpdateById'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/single/id/{Id}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleById implements IReturn<BiochemistrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the biochemistry to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiochemistryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistrySingleResponse(); }
    public getTypeName() { return 'BiochemistryGetSingleById'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/collection", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryCollectionResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetCollection implements IReturn<BiochemistryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BiochemistryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistryCollectionResponse(); }
    public getTypeName() { return 'BiochemistryGetCollection'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/create", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostCreate implements IReturn<BiochemistrySingleResponse>
{
    /**
    * The metadata to use when creating the biochemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the biochemistry.
    */
    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistrySingleResponse(); }
    public getTypeName() { return 'BiochemistryPostCreate'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/save", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostSave implements IReturn<BiochemistrySingleResponse>
{
    /**
    * The metadata to use when creating the biochemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The biochemistry to save.
    */
    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistrySingleResponse(); }
    public getTypeName() { return 'BiochemistryPostSave'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/validate", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistryValidationResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostValidate implements IReturn<BiochemistryValidationResponse>
{
    /**
    * The metadata to use when validating the biochemistry.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The biochemistry to validate.
    */
    // @DataMember(Order=2)
    public data: Biochemistry;

    public constructor(init?: Partial<BiochemistryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistryValidationResponse(); }
    public getTypeName() { return 'BiochemistryPostValidate'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryGetSingleByMedicalReviewIdAndRepeat implements IReturn<BiochemistrySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the biochemistry to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BiochemistryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistrySingleResponse(); }
    public getTypeName() { return 'BiochemistryGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Biochemistry
*/
// @Route("/medical-review/form/biochemistry/single/update", "POST")
// @Api(Description="Form - Biochemistry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BiochemistrySingleResponse)", StatusCode=500)
// @DataContract
export class BiochemistryPostUpdateById implements IReturn<BiochemistrySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BiochemistryPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BiochemistrySingleResponse(); }
    public getTypeName() { return 'BiochemistryPostUpdateById'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleById implements IReturn<BreastCancerRecurrenceSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleById'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/collection", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetCollection implements IReturn<BreastCancerRecurrenceCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceCollectionResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetCollection'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/create", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostCreate implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast cancer recurrence.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostCreate'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/save", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostSave implements IReturn<BreastCancerRecurrenceSingleResponse>
{
    /**
    * The metadata to use when creating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to save.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostSave'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/validate", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostValidate implements IReturn<BreastCancerRecurrenceValidationResponse>
{
    /**
    * The metadata to use when validating the breast cancer recurrence.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast cancer recurrence to validate.
    */
    // @DataMember(Order=2)
    public data: BreastCancerRecurrence;

    public constructor(init?: Partial<BreastCancerRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceValidationResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostValidate'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrenceGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the breast cancer recurrence to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastCancerRecurrenceGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrenceGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - BreastCancerRecurrence
*/
// @Route("/medical-review/form/breast-cancer-recurrence/single/update", "POST")
// @Api(Description="Form - BreastCancerRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastCancerRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class BreastCancerRecurrencePostUpdateById implements IReturn<BreastCancerRecurrenceSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastCancerRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastCancerRecurrenceSingleResponse(); }
    public getTypeName() { return 'BreastCancerRecurrencePostUpdateById'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/id/{Id}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleById implements IReturn<BreastUltrasoundSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'BreastUltrasoundGetSingleById'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/collection", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetCollection implements IReturn<BreastUltrasoundCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastUltrasoundGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundCollectionResponse(); }
    public getTypeName() { return 'BreastUltrasoundGetCollection'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/create", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostCreate implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'BreastUltrasoundPostCreate'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/save", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostSave implements IReturn<BreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to save.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'BreastUltrasoundPostSave'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/validate", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundValidationResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostValidate implements IReturn<BreastUltrasoundValidationResponse>
{
    /**
    * The metadata to use when validating the breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The breast ultrasound to validate.
    */
    // @DataMember(Order=2)
    public data: BreastUltrasound;

    public constructor(init?: Partial<BreastUltrasoundPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundValidationResponse(); }
    public getTypeName() { return 'BreastUltrasoundPostValidate'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastUltrasoundSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the breast ultrasound to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'BreastUltrasoundGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - BreastUltrasound
*/
// @Route("/medical-review/form/breast-ultrasound/single/update", "POST")
// @Api(Description="Form - BreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class BreastUltrasoundPostUpdateById implements IReturn<BreastUltrasoundSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastUltrasoundPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'BreastUltrasoundPostUpdateById'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/single/id/{Id}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetSingleById implements IReturn<ClinicalTumourMeasurementSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the clinical tumour measurement to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetSingleById'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/collection", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetCollection implements IReturn<ClinicalTumourMeasurementCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCollectionResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetCollection'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/create", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostCreate implements IReturn<ClinicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the clinical tumour measurement.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostCreate'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/save", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostSave implements IReturn<ClinicalTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement to save.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostSave'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/validate", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostValidate implements IReturn<ClinicalTumourMeasurementValidationResponse>
{
    /**
    * The metadata to use when validating the clinical tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement to validate.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurement;

    public constructor(init?: Partial<ClinicalTumourMeasurementPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementValidationResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostValidate'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementGetSingleByMedicalReviewIdAndRepeat implements IReturn<ClinicalTumourMeasurementSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the clinical tumour measurement to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ClinicalTumourMeasurement
*/
// @Route("/medical-review/form/clinical-tumour-measurement/single/update", "POST")
// @Api(Description="Form - ClinicalTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementPostUpdateById implements IReturn<ClinicalTumourMeasurementSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ClinicalTumourMeasurementPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementPostUpdateById'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/single/id/{Id}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCycleGetSingleById implements IReturn<ClinicalTumourMeasurementCycleSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the clinical tumour measurement cycle to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCycleGetSingleById'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/collection", "GET")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCycleGetCollection implements IReturn<ClinicalTumourMeasurementCycleCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleCollectionResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCycleGetCollection'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/create", "POST")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCyclePostCreate implements IReturn<ClinicalTumourMeasurementCycleSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement cycle.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the clinical tumour measurement cycle.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurementCycle;

    public constructor(init?: Partial<ClinicalTumourMeasurementCyclePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCyclePostCreate'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/save", "POST")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCyclePostSave implements IReturn<ClinicalTumourMeasurementCycleSingleResponse>
{
    /**
    * The metadata to use when creating the clinical tumour measurement cycle.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement cycle to save.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurementCycle;

    public constructor(init?: Partial<ClinicalTumourMeasurementCyclePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCyclePostSave'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/validate", "POST")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCyclePostValidate implements IReturn<ClinicalTumourMeasurementCycleValidationResponse>
{
    /**
    * The metadata to use when validating the clinical tumour measurement cycle.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The clinical tumour measurement cycle to validate.
    */
    // @DataMember(Order=2)
    public data: ClinicalTumourMeasurementCycle;

    public constructor(init?: Partial<ClinicalTumourMeasurementCyclePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleValidationResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCyclePostValidate'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCycleGetSingleByMedicalReviewIdAndRepeat implements IReturn<ClinicalTumourMeasurementCycleSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the clinical tumour measurement cycle to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ClinicalTumourMeasurementCycleGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCycleGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ClinicalTumourMeasurementCycle
*/
// @Route("/medical-review/form/clinical-tumour-measurement-cycle/single/update", "POST")
// @Api(Description="Form - ClinicalTumourMeasurementCycle")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalTumourMeasurementCycleSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalTumourMeasurementCyclePostUpdateById implements IReturn<ClinicalTumourMeasurementCycleSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ClinicalTumourMeasurementCyclePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClinicalTumourMeasurementCycleSingleResponse(); }
    public getTypeName() { return 'ClinicalTumourMeasurementCyclePostUpdateById'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetSingleById implements IReturn<ConcomitantMedicationItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medication item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemGetSingleById'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/collection", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetCollection implements IReturn<ConcomitantMedicationItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemCollectionResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemGetCollection'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/create", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostCreate implements IReturn<ConcomitantMedicationItemSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medication item.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemPostCreate'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/save", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostSave implements IReturn<ConcomitantMedicationItemSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication item to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemPostSave'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/validate", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostValidate implements IReturn<ConcomitantMedicationItemValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medication item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medication item to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedicationItem;

    public constructor(init?: Partial<ConcomitantMedicationItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemValidationResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemPostValidate'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the concomitant medication item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ConcomitantMedicationItem
*/
// @Route("/medical-review/form/concomitant-medication-item/single/update", "POST")
// @Api(Description="Form - ConcomitantMedicationItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationItemSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationItemPostUpdateById implements IReturn<ConcomitantMedicationItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationItemPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationItemSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationItemPostUpdateById'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/single/id/{Id}", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementGetSingleById implements IReturn<DiagnosticTumourMeasurementSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the diagnostic tumour measurement to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementGetSingleById'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/collection", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementGetCollection implements IReturn<DiagnosticTumourMeasurementCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementCollectionResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementGetCollection'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/create", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementPostCreate implements IReturn<DiagnosticTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the diagnostic tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the diagnostic tumour measurement.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurement;

    public constructor(init?: Partial<DiagnosticTumourMeasurementPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementPostCreate'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/save", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementPostSave implements IReturn<DiagnosticTumourMeasurementSingleResponse>
{
    /**
    * The metadata to use when creating the diagnostic tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The diagnostic tumour measurement to save.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurement;

    public constructor(init?: Partial<DiagnosticTumourMeasurementPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementPostSave'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/validate", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementPostValidate implements IReturn<DiagnosticTumourMeasurementValidationResponse>
{
    /**
    * The metadata to use when validating the diagnostic tumour measurement.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The diagnostic tumour measurement to validate.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurement;

    public constructor(init?: Partial<DiagnosticTumourMeasurementPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementValidationResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementPostValidate'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementGetSingleByMedicalReviewIdAndRepeat implements IReturn<DiagnosticTumourMeasurementSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the diagnostic tumour measurement to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - DiagnosticTumourMeasurement
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement/single/update", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurement")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementPostUpdateById implements IReturn<DiagnosticTumourMeasurementSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DiagnosticTumourMeasurementPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementPostUpdateById'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/single/id/{Id}", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStageGetSingleById implements IReturn<DiagnosticTumourMeasurementStageSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the diagnostic tumour measurement stage to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStageGetSingleById'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/collection", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStageGetCollection implements IReturn<DiagnosticTumourMeasurementStageCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageCollectionResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStageGetCollection'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/create", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStagePostCreate implements IReturn<DiagnosticTumourMeasurementStageSingleResponse>
{
    /**
    * The metadata to use when creating the diagnostic tumour measurement stage.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the diagnostic tumour measurement stage.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurementStage;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStagePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStagePostCreate'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/save", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStagePostSave implements IReturn<DiagnosticTumourMeasurementStageSingleResponse>
{
    /**
    * The metadata to use when creating the diagnostic tumour measurement stage.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The diagnostic tumour measurement stage to save.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurementStage;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStagePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStagePostSave'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/validate", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStagePostValidate implements IReturn<DiagnosticTumourMeasurementStageValidationResponse>
{
    /**
    * The metadata to use when validating the diagnostic tumour measurement stage.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The diagnostic tumour measurement stage to validate.
    */
    // @DataMember(Order=2)
    public data: DiagnosticTumourMeasurementStage;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStagePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageValidationResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStagePostValidate'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStageGetSingleByMedicalReviewIdAndRepeat implements IReturn<DiagnosticTumourMeasurementStageSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the diagnostic tumour measurement stage to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DiagnosticTumourMeasurementStageGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStageGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - DiagnosticTumourMeasurementStage
*/
// @Route("/medical-review/form/diagnostic-tumour-measurement-stage/single/update", "POST")
// @Api(Description="Form - DiagnosticTumourMeasurementStage")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DiagnosticTumourMeasurementStageSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticTumourMeasurementStagePostUpdateById implements IReturn<DiagnosticTumourMeasurementStageSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DiagnosticTumourMeasurementStagePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new DiagnosticTumourMeasurementStageSingleResponse(); }
    public getTypeName() { return 'DiagnosticTumourMeasurementStagePostUpdateById'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/id/{Id}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleById implements IReturn<HaematologySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the haematology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HaematologyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologySingleResponse(); }
    public getTypeName() { return 'HaematologyGetSingleById'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/collection", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyCollectionResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetCollection implements IReturn<HaematologyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HaematologyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologyCollectionResponse(); }
    public getTypeName() { return 'HaematologyGetCollection'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/create", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostCreate implements IReturn<HaematologySingleResponse>
{
    /**
    * The metadata to use when creating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the haematology.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologySingleResponse(); }
    public getTypeName() { return 'HaematologyPostCreate'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/save", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostSave implements IReturn<HaematologySingleResponse>
{
    /**
    * The metadata to use when creating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The haematology to save.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologySingleResponse(); }
    public getTypeName() { return 'HaematologyPostSave'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/validate", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologyValidationResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostValidate implements IReturn<HaematologyValidationResponse>
{
    /**
    * The metadata to use when validating the haematology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The haematology to validate.
    */
    // @DataMember(Order=2)
    public data: Haematology;

    public constructor(init?: Partial<HaematologyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologyValidationResponse(); }
    public getTypeName() { return 'HaematologyPostValidate'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<HaematologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the haematology to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HaematologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologySingleResponse(); }
    public getTypeName() { return 'HaematologyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Haematology
*/
// @Route("/medical-review/form/haematology/single/update", "POST")
// @Api(Description="Form - Haematology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HaematologySingleResponse)", StatusCode=500)
// @DataContract
export class HaematologyPostUpdateById implements IReturn<HaematologySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HaematologyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new HaematologySingleResponse(); }
    public getTypeName() { return 'HaematologyPostUpdateById'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/single/id/{Id}", "GET")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=500)
// @DataContract
export class MammogramGetSingleById implements IReturn<MammogramSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the mammogram to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MammogramGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramSingleResponse(); }
    public getTypeName() { return 'MammogramGetSingleById'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/collection", "GET")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramCollectionResponse)", StatusCode=500)
// @DataContract
export class MammogramGetCollection implements IReturn<MammogramCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MammogramGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramCollectionResponse(); }
    public getTypeName() { return 'MammogramGetCollection'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/create", "POST")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=500)
// @DataContract
export class MammogramPostCreate implements IReturn<MammogramSingleResponse>
{
    /**
    * The metadata to use when creating the mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the mammogram.
    */
    // @DataMember(Order=2)
    public data: Mammogram;

    public constructor(init?: Partial<MammogramPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramSingleResponse(); }
    public getTypeName() { return 'MammogramPostCreate'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/save", "POST")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=500)
// @DataContract
export class MammogramPostSave implements IReturn<MammogramSingleResponse>
{
    /**
    * The metadata to use when creating the mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The mammogram to save.
    */
    // @DataMember(Order=2)
    public data: Mammogram;

    public constructor(init?: Partial<MammogramPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramSingleResponse(); }
    public getTypeName() { return 'MammogramPostSave'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/validate", "POST")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramValidationResponse)", StatusCode=500)
// @DataContract
export class MammogramPostValidate implements IReturn<MammogramValidationResponse>
{
    /**
    * The metadata to use when validating the mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The mammogram to validate.
    */
    // @DataMember(Order=2)
    public data: Mammogram;

    public constructor(init?: Partial<MammogramPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramValidationResponse(); }
    public getTypeName() { return 'MammogramPostValidate'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=500)
// @DataContract
export class MammogramGetSingleByMedicalReviewIdAndRepeat implements IReturn<MammogramSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the mammogram to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MammogramGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramSingleResponse(); }
    public getTypeName() { return 'MammogramGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Mammogram
*/
// @Route("/medical-review/form/mammogram/single/update", "POST")
// @Api(Description="Form - Mammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MammogramSingleResponse)", StatusCode=500)
// @DataContract
export class MammogramPostUpdateById implements IReturn<MammogramSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MammogramPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MammogramSingleResponse(); }
    public getTypeName() { return 'MammogramPostUpdateById'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetSingleById implements IReturn<MedicalHistoryItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
    public getTypeName() { return 'MedicalHistoryItemGetSingleById'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/collection", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetCollection implements IReturn<MedicalHistoryItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemCollectionResponse(); }
    public getTypeName() { return 'MedicalHistoryItemGetCollection'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/create", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostCreate implements IReturn<MedicalHistoryItemSingleResponse>
{
    /**
    * The metadata to use when creating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history item.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
    public getTypeName() { return 'MedicalHistoryItemPostCreate'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/save", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostSave implements IReturn<MedicalHistoryItemSingleResponse>
{
    /**
    * The metadata to use when creating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history item to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
    public getTypeName() { return 'MedicalHistoryItemPostSave'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/validate", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostValidate implements IReturn<MedicalHistoryItemValidationResponse>
{
    /**
    * The metadata to use when validating the medical history item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history item to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistoryItem;

    public constructor(init?: Partial<MedicalHistoryItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemValidationResponse(); }
    public getTypeName() { return 'MedicalHistoryItemPostValidate'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistoryItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the medical history item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
    public getTypeName() { return 'MedicalHistoryItemGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - MedicalHistoryItem
*/
// @Route("/medical-review/form/medical-history-item/single/update", "POST")
// @Api(Description="Form - MedicalHistoryItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryItemSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryItemPostUpdateById implements IReturn<MedicalHistoryItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryItemPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryItemSingleResponse(); }
    public getTypeName() { return 'MedicalHistoryItemPostUpdateById'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetSingleById implements IReturn<NeoadjuvantTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the neoadjuvant therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetSingleById'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/collection", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetCollection implements IReturn<NeoadjuvantTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapyCollectionResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetCollection'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/create", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostCreate implements IReturn<NeoadjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the neoadjuvant therapy.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostCreate'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/save", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostSave implements IReturn<NeoadjuvantTherapySingleResponse>
{
    /**
    * The metadata to use when creating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neoadjuvant therapy to save.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostSave'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/validate", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostValidate implements IReturn<NeoadjuvantTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the neoadjuvant therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The neoadjuvant therapy to validate.
    */
    // @DataMember(Order=2)
    public data: NeoadjuvantTherapy;

    public constructor(init?: Partial<NeoadjuvantTherapyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapyValidationResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostValidate'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<NeoadjuvantTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the neoadjuvant therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NeoadjuvantTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - NeoadjuvantTherapy
*/
// @Route("/medical-review/form/neoadjuvant-therapy/single/update", "POST")
// @Api(Description="Form - NeoadjuvantTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NeoadjuvantTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NeoadjuvantTherapyPostUpdateById implements IReturn<NeoadjuvantTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NeoadjuvantTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new NeoadjuvantTherapySingleResponse(); }
    public getTypeName() { return 'NeoadjuvantTherapyPostUpdateById'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/single/id/{Id}", "GET")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundGetSingleById implements IReturn<PostAnthracylineBreastUltrasoundSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the post anthracyline breast ultrasound to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundGetSingleById'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/collection", "GET")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundCollectionResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundGetCollection implements IReturn<PostAnthracylineBreastUltrasoundCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundCollectionResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundGetCollection'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/create", "POST")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundPostCreate implements IReturn<PostAnthracylineBreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the post anthracyline breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the post anthracyline breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineBreastUltrasound;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundPostCreate'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/save", "POST")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundPostSave implements IReturn<PostAnthracylineBreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the post anthracyline breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The post anthracyline breast ultrasound to save.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineBreastUltrasound;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundPostSave'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/validate", "POST")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundValidationResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundPostValidate implements IReturn<PostAnthracylineBreastUltrasoundValidationResponse>
{
    /**
    * The metadata to use when validating the post anthracyline breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The post anthracyline breast ultrasound to validate.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineBreastUltrasound;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundValidationResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundPostValidate'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat implements IReturn<PostAnthracylineBreastUltrasoundSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the post anthracyline breast ultrasound to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - PostAnthracylineBreastUltrasound
*/
// @Route("/medical-review/form/post-anthracyline-breast-ultrasound/single/update", "POST")
// @Api(Description="Form - PostAnthracylineBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineBreastUltrasoundPostUpdateById implements IReturn<PostAnthracylineBreastUltrasoundSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<PostAnthracylineBreastUltrasoundPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineBreastUltrasoundPostUpdateById'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/single/id/{Id}", "GET")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramGetSingleById implements IReturn<PostAnthracylineMammogramSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the post anthracyline mammogram to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PostAnthracylineMammogramGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramGetSingleById'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/collection", "GET")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramCollectionResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramGetCollection implements IReturn<PostAnthracylineMammogramCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PostAnthracylineMammogramGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramCollectionResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramGetCollection'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/create", "POST")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramPostCreate implements IReturn<PostAnthracylineMammogramSingleResponse>
{
    /**
    * The metadata to use when creating the post anthracyline mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the post anthracyline mammogram.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineMammogram;

    public constructor(init?: Partial<PostAnthracylineMammogramPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramPostCreate'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/save", "POST")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramPostSave implements IReturn<PostAnthracylineMammogramSingleResponse>
{
    /**
    * The metadata to use when creating the post anthracyline mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The post anthracyline mammogram to save.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineMammogram;

    public constructor(init?: Partial<PostAnthracylineMammogramPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramPostSave'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/validate", "POST")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramValidationResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramPostValidate implements IReturn<PostAnthracylineMammogramValidationResponse>
{
    /**
    * The metadata to use when validating the post anthracyline mammogram.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The post anthracyline mammogram to validate.
    */
    // @DataMember(Order=2)
    public data: PostAnthracylineMammogram;

    public constructor(init?: Partial<PostAnthracylineMammogramPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramValidationResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramPostValidate'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramGetSingleByMedicalReviewIdAndRepeat implements IReturn<PostAnthracylineMammogramSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the post anthracyline mammogram to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PostAnthracylineMammogramGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - PostAnthracylineMammogram
*/
// @Route("/medical-review/form/post-anthracyline-mammogram/single/update", "POST")
// @Api(Description="Form - PostAnthracylineMammogram")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PostAnthracylineMammogramSingleResponse)", StatusCode=500)
// @DataContract
export class PostAnthracylineMammogramPostUpdateById implements IReturn<PostAnthracylineMammogramSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<PostAnthracylineMammogramPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostAnthracylineMammogramSingleResponse(); }
    public getTypeName() { return 'PostAnthracylineMammogramPostUpdateById'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleById implements IReturn<ProtocolDeviationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the protocol deviation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
    public getTypeName() { return 'ProtocolDeviationGetSingleById'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/collection", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetCollection implements IReturn<ProtocolDeviationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationCollectionResponse(); }
    public getTypeName() { return 'ProtocolDeviationGetCollection'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/create", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostCreate implements IReturn<ProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the protocol deviation.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
    public getTypeName() { return 'ProtocolDeviationPostCreate'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/save", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostSave implements IReturn<ProtocolDeviationSingleResponse>
{
    /**
    * The metadata to use when creating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The protocol deviation to save.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
    public getTypeName() { return 'ProtocolDeviationPostSave'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/validate", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostValidate implements IReturn<ProtocolDeviationValidationResponse>
{
    /**
    * The metadata to use when validating the protocol deviation.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The protocol deviation to validate.
    */
    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationValidationResponse(); }
    public getTypeName() { return 'ProtocolDeviationPostValidate'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the protocol deviation to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
    public getTypeName() { return 'ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ProtocolDeviation
*/
// @Route("/medical-review/form/protocol-deviation/single/update", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostUpdateById implements IReturn<ProtocolDeviationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
    public getTypeName() { return 'ProtocolDeviationPostUpdateById'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/id/{Id}", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetSingleById implements IReturn<SeriousAdverseEventItemSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the serious adverse event item to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemGetSingleById'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/collection", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetCollection implements IReturn<SeriousAdverseEventItemCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemCollectionResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemGetCollection'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/create", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostCreate implements IReturn<SeriousAdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the serious adverse event item.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemPostCreate'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/save", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostSave implements IReturn<SeriousAdverseEventItemSingleResponse>
{
    /**
    * The metadata to use when creating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event item to save.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemPostSave'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/validate", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostValidate implements IReturn<SeriousAdverseEventItemValidationResponse>
{
    /**
    * The metadata to use when validating the serious adverse event item.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The serious adverse event item to validate.
    */
    // @DataMember(Order=2)
    public data: SeriousAdverseEventItem;

    public constructor(init?: Partial<SeriousAdverseEventItemPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemValidationResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemPostValidate'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat implements IReturn<SeriousAdverseEventItemSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the serious adverse event item to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SeriousAdverseEventItem
*/
// @Route("/medical-review/form/serious-adverse-event-item/single/update", "POST")
// @Api(Description="Form - SeriousAdverseEventItem")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventItemSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventItemPostUpdateById implements IReturn<SeriousAdverseEventItemSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SeriousAdverseEventItemPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SeriousAdverseEventItemSingleResponse(); }
    public getTypeName() { return 'SeriousAdverseEventItemPostUpdateById'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/id/{Id}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleById implements IReturn<SlnbResultSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the slnb result to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SlnbResultGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultGetSingleById'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/collection", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultCollectionResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetCollection implements IReturn<SlnbResultCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SlnbResultGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultCollectionResponse(); }
    public getTypeName() { return 'SlnbResultGetCollection'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/create", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostCreate implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the slnb result.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostCreate'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/save", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostSave implements IReturn<SlnbResultSingleResponse>
{
    /**
    * The metadata to use when creating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to save.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostSave'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/validate", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultValidationResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostValidate implements IReturn<SlnbResultValidationResponse>
{
    /**
    * The metadata to use when validating the slnb result.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The slnb result to validate.
    */
    // @DataMember(Order=2)
    public data: SlnbResult;

    public constructor(init?: Partial<SlnbResultPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultValidationResponse(); }
    public getTypeName() { return 'SlnbResultPostValidate'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultGetSingleByMedicalReviewIdAndRepeat implements IReturn<SlnbResultSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the slnb result to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SlnbResultGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SlnbResult
*/
// @Route("/medical-review/form/slnb-result/single/update", "POST")
// @Api(Description="Form - SlnbResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SlnbResultSingleResponse)", StatusCode=500)
// @DataContract
export class SlnbResultPostUpdateById implements IReturn<SlnbResultSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SlnbResultPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SlnbResultSingleResponse(); }
    public getTypeName() { return 'SlnbResultPostUpdateById'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentSingleResponse(); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/collection", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/create", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostCreate implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The metadata to use when creating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the source document.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentSingleResponse(); }
    public getTypeName() { return 'SourceDocumentPostCreate'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/save", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The metadata to use when creating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The source document to save.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentSingleResponse(); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/validate", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The metadata to use when validating the source document.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The source document to validate.
    */
    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentValidationResponse(); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByMedicalReviewIdAndRepeat implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the source document to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentSingleResponse(); }
    public getTypeName() { return 'SourceDocumentGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SourceDocument
*/
// @Route("/medical-review/form/source-document/single/update", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostUpdateById implements IReturn<SourceDocumentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SourceDocumentPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SourceDocumentSingleResponse(); }
    public getTypeName() { return 'SourceDocumentPostUpdateById'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/single/id/{Id}", "GET")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundGetSingleById implements IReturn<SurgeryBreastUltrasoundSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgery breast ultrasound to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryBreastUltrasoundGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundGetSingleById'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/collection", "GET")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundGetCollection implements IReturn<SurgeryBreastUltrasoundCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryBreastUltrasoundGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundCollectionResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundGetCollection'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/create", "POST")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundPostCreate implements IReturn<SurgeryBreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the surgery breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery breast ultrasound.
    */
    // @DataMember(Order=2)
    public data: SurgeryBreastUltrasound;

    public constructor(init?: Partial<SurgeryBreastUltrasoundPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundPostCreate'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/save", "POST")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundPostSave implements IReturn<SurgeryBreastUltrasoundSingleResponse>
{
    /**
    * The metadata to use when creating the surgery breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery breast ultrasound to save.
    */
    // @DataMember(Order=2)
    public data: SurgeryBreastUltrasound;

    public constructor(init?: Partial<SurgeryBreastUltrasoundPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundPostSave'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/validate", "POST")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundPostValidate implements IReturn<SurgeryBreastUltrasoundValidationResponse>
{
    /**
    * The metadata to use when validating the surgery breast ultrasound.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery breast ultrasound to validate.
    */
    // @DataMember(Order=2)
    public data: SurgeryBreastUltrasound;

    public constructor(init?: Partial<SurgeryBreastUltrasoundPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundValidationResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundPostValidate'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgeryBreastUltrasoundSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the surgery breast ultrasound to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SurgeryBreastUltrasound
*/
// @Route("/medical-review/form/surgery-breast-ultrasound/single/update", "POST")
// @Api(Description="Form - SurgeryBreastUltrasound")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryBreastUltrasoundSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryBreastUltrasoundPostUpdateById implements IReturn<SurgeryBreastUltrasoundSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryBreastUltrasoundPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryBreastUltrasoundSingleResponse(); }
    public getTypeName() { return 'SurgeryBreastUltrasoundPostUpdateById'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/single/id/{Id}", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetSingleById implements IReturn<SurgeryTypeSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgery type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypeGetSingleById'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/collection", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetCollection implements IReturn<SurgeryTypeCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeCollectionResponse(); }
    public getTypeName() { return 'SurgeryTypeGetCollection'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/create", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostCreate implements IReturn<SurgeryTypeSingleResponse>
{
    /**
    * The metadata to use when creating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery type.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostCreate'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/save", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostSave implements IReturn<SurgeryTypeSingleResponse>
{
    /**
    * The metadata to use when creating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery type to save.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostSave'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/validate", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostValidate implements IReturn<SurgeryTypeValidationResponse>
{
    /**
    * The metadata to use when validating the surgery type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery type to validate.
    */
    // @DataMember(Order=2)
    public data: SurgeryType;

    public constructor(init?: Partial<SurgeryTypePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeValidationResponse(); }
    public getTypeName() { return 'SurgeryTypePostValidate'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypeGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgeryTypeSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the surgery type to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryTypeGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypeGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SurgeryType
*/
// @Route("/medical-review/form/surgery-type/single/update", "POST")
// @Api(Description="Form - SurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryTypePostUpdateById implements IReturn<SurgeryTypeSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryTypePostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryTypeSingleResponse(); }
    public getTypeName() { return 'SurgeryTypePostUpdateById'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/id/{Id}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleById implements IReturn<ThyroidFunctionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the thyroid function to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
    public getTypeName() { return 'ThyroidFunctionGetSingleById'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/collection", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetCollection implements IReturn<ThyroidFunctionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ThyroidFunctionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionCollectionResponse(); }
    public getTypeName() { return 'ThyroidFunctionGetCollection'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/create", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostCreate implements IReturn<ThyroidFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the thyroid function.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
    public getTypeName() { return 'ThyroidFunctionPostCreate'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/save", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostSave implements IReturn<ThyroidFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The thyroid function to save.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
    public getTypeName() { return 'ThyroidFunctionPostSave'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/validate", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostValidate implements IReturn<ThyroidFunctionValidationResponse>
{
    /**
    * The metadata to use when validating the thyroid function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The thyroid function to validate.
    */
    // @DataMember(Order=2)
    public data: ThyroidFunction;

    public constructor(init?: Partial<ThyroidFunctionPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionValidationResponse(); }
    public getTypeName() { return 'ThyroidFunctionPostValidate'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ThyroidFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the thyroid function to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
    public getTypeName() { return 'ThyroidFunctionGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ThyroidFunction
*/
// @Route("/medical-review/form/thyroid-function/single/update", "POST")
// @Api(Description="Form - ThyroidFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ThyroidFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class ThyroidFunctionPostUpdateById implements IReturn<ThyroidFunctionSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ThyroidFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ThyroidFunctionSingleResponse(); }
    public getTypeName() { return 'ThyroidFunctionPostUpdateById'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/single/id/{Id}", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetSingleById implements IReturn<UrinalysisSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UrinalysisGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisSingleResponse(); }
    public getTypeName() { return 'UrinalysisGetSingleById'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/collection", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisCollectionResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetCollection implements IReturn<UrinalysisCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UrinalysisGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisCollectionResponse(); }
    public getTypeName() { return 'UrinalysisGetCollection'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/create", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostCreate implements IReturn<UrinalysisSingleResponse>
{
    /**
    * The metadata to use when creating the urinalysis.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the urinalysis.
    */
    // @DataMember(Order=2)
    public data: Urinalysis;

    public constructor(init?: Partial<UrinalysisPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisSingleResponse(); }
    public getTypeName() { return 'UrinalysisPostCreate'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/save", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostSave implements IReturn<UrinalysisSingleResponse>
{
    /**
    * The metadata to use when creating the urinalysis.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The urinalysis to save.
    */
    // @DataMember(Order=2)
    public data: Urinalysis;

    public constructor(init?: Partial<UrinalysisPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisSingleResponse(); }
    public getTypeName() { return 'UrinalysisPostSave'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/validate", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisValidationResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostValidate implements IReturn<UrinalysisValidationResponse>
{
    /**
    * The metadata to use when validating the urinalysis.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The urinalysis to validate.
    */
    // @DataMember(Order=2)
    public data: Urinalysis;

    public constructor(init?: Partial<UrinalysisPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisValidationResponse(); }
    public getTypeName() { return 'UrinalysisPostValidate'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetSingleByMedicalReviewIdAndRepeat implements IReturn<UrinalysisSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<UrinalysisGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisSingleResponse(); }
    public getTypeName() { return 'UrinalysisGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Urinalysis
*/
// @Route("/medical-review/form/urinalysis/single/update", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostUpdateById implements IReturn<UrinalysisSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UrinalysisPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new UrinalysisSingleResponse(); }
    public getTypeName() { return 'UrinalysisPostUpdateById'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/id/{Id}", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetSingleById implements IReturn<AdverseEventsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the adverse events to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventsGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsSingleResponse(); }
    public getTypeName() { return 'AdverseEventsGetSingleById'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/collection", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetCollection implements IReturn<AdverseEventsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdverseEventsGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsCollectionResponse(); }
    public getTypeName() { return 'AdverseEventsGetCollection'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/create", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostCreate implements IReturn<AdverseEventsSingleResponse>
{
    /**
    * The metadata to use when creating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the adverse events.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsSingleResponse(); }
    public getTypeName() { return 'AdverseEventsPostCreate'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/save", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostSave implements IReturn<AdverseEventsSingleResponse>
{
    /**
    * The metadata to use when creating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse events to save.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsSingleResponse(); }
    public getTypeName() { return 'AdverseEventsPostSave'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/validate", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostValidate implements IReturn<AdverseEventsValidationResponse>
{
    /**
    * The metadata to use when validating the adverse events.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The adverse events to validate.
    */
    // @DataMember(Order=2)
    public data: AdverseEvents;

    public constructor(init?: Partial<AdverseEventsPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsValidationResponse(); }
    public getTypeName() { return 'AdverseEventsPostValidate'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdverseEventsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the adverse events to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdverseEventsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsSingleResponse(); }
    public getTypeName() { return 'AdverseEventsGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - AdverseEvents
*/
// @Route("/medical-review/form/adverse-events/single/update", "POST")
// @Api(Description="Form - AdverseEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdverseEventsSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventsPostUpdateById implements IReturn<AdverseEventsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdverseEventsPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new AdverseEventsSingleResponse(); }
    public getTypeName() { return 'AdverseEventsPostUpdateById'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/single/id/{Id}", "GET")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyGetSingleById implements IReturn<BaselineHistopathologySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the baseline histopathology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BaselineHistopathologyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologySingleResponse(); }
    public getTypeName() { return 'BaselineHistopathologyGetSingleById'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/collection", "GET")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyGetCollection implements IReturn<BaselineHistopathologyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BaselineHistopathologyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologyCollectionResponse(); }
    public getTypeName() { return 'BaselineHistopathologyGetCollection'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/create", "POST")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyPostCreate implements IReturn<BaselineHistopathologySingleResponse>
{
    /**
    * The metadata to use when creating the baseline histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the baseline histopathology.
    */
    // @DataMember(Order=2)
    public data: BaselineHistopathology;

    public constructor(init?: Partial<BaselineHistopathologyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologySingleResponse(); }
    public getTypeName() { return 'BaselineHistopathologyPostCreate'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/save", "POST")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyPostSave implements IReturn<BaselineHistopathologySingleResponse>
{
    /**
    * The metadata to use when creating the baseline histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The baseline histopathology to save.
    */
    // @DataMember(Order=2)
    public data: BaselineHistopathology;

    public constructor(init?: Partial<BaselineHistopathologyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologySingleResponse(); }
    public getTypeName() { return 'BaselineHistopathologyPostSave'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/validate", "POST")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologyValidationResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyPostValidate implements IReturn<BaselineHistopathologyValidationResponse>
{
    /**
    * The metadata to use when validating the baseline histopathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The baseline histopathology to validate.
    */
    // @DataMember(Order=2)
    public data: BaselineHistopathology;

    public constructor(init?: Partial<BaselineHistopathologyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologyValidationResponse(); }
    public getTypeName() { return 'BaselineHistopathologyPostValidate'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<BaselineHistopathologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the baseline histopathology to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BaselineHistopathologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologySingleResponse(); }
    public getTypeName() { return 'BaselineHistopathologyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - BaselineHistopathology
*/
// @Route("/medical-review/form/baseline-histopathology/single/update", "POST")
// @Api(Description="Form - BaselineHistopathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineHistopathologySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineHistopathologyPostUpdateById implements IReturn<BaselineHistopathologySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BaselineHistopathologyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaselineHistopathologySingleResponse(); }
    public getTypeName() { return 'BaselineHistopathologyPostUpdateById'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyGetSingleById implements IReturn<ChariotTherapySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the chariot therapy to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ChariotTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapySingleResponse(); }
    public getTypeName() { return 'ChariotTherapyGetSingleById'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/collection", "GET")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyGetCollection implements IReturn<ChariotTherapyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ChariotTherapyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapyCollectionResponse(); }
    public getTypeName() { return 'ChariotTherapyGetCollection'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/create", "POST")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyPostCreate implements IReturn<ChariotTherapySingleResponse>
{
    /**
    * The metadata to use when creating the chariot therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the chariot therapy.
    */
    // @DataMember(Order=2)
    public data: ChariotTherapy;

    public constructor(init?: Partial<ChariotTherapyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapySingleResponse(); }
    public getTypeName() { return 'ChariotTherapyPostCreate'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/save", "POST")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyPostSave implements IReturn<ChariotTherapySingleResponse>
{
    /**
    * The metadata to use when creating the chariot therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The chariot therapy to save.
    */
    // @DataMember(Order=2)
    public data: ChariotTherapy;

    public constructor(init?: Partial<ChariotTherapyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapySingleResponse(); }
    public getTypeName() { return 'ChariotTherapyPostSave'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/validate", "POST")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyPostValidate implements IReturn<ChariotTherapyValidationResponse>
{
    /**
    * The metadata to use when validating the chariot therapy.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The chariot therapy to validate.
    */
    // @DataMember(Order=2)
    public data: ChariotTherapy;

    public constructor(init?: Partial<ChariotTherapyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapyValidationResponse(); }
    public getTypeName() { return 'ChariotTherapyPostValidate'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<ChariotTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the chariot therapy to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ChariotTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapySingleResponse(); }
    public getTypeName() { return 'ChariotTherapyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ChariotTherapy
*/
// @Route("/medical-review/form/chariot-therapy/single/update", "POST")
// @Api(Description="Form - ChariotTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ChariotTherapySingleResponse)", StatusCode=500)
// @DataContract
export class ChariotTherapyPostUpdateById implements IReturn<ChariotTherapySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ChariotTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ChariotTherapySingleResponse(); }
    public getTypeName() { return 'ChariotTherapyPostUpdateById'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleById implements IReturn<ConcomitantMedicationsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the concomitant medications to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleById'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/collection", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetCollection implements IReturn<ConcomitantMedicationsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsCollectionResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsGetCollection'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/create", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostCreate implements IReturn<ConcomitantMedicationsSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the concomitant medications.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsPostCreate'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/save", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostSave implements IReturn<ConcomitantMedicationsSingleResponse>
{
    /**
    * The metadata to use when creating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medications to save.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsPostSave'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/validate", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostValidate implements IReturn<ConcomitantMedicationsValidationResponse>
{
    /**
    * The metadata to use when validating the concomitant medications.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The concomitant medications to validate.
    */
    // @DataMember(Order=2)
    public data: ConcomitantMedications;

    public constructor(init?: Partial<ConcomitantMedicationsPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsValidationResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsPostValidate'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the concomitant medications to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - ConcomitantMedications
*/
// @Route("/medical-review/form/concomitant-medications/single/update", "POST")
// @Api(Description="Form - ConcomitantMedications")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationsSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationsPostUpdateById implements IReturn<ConcomitantMedicationsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationsPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConcomitantMedicationsSingleResponse(); }
    public getTypeName() { return 'ConcomitantMedicationsPostUpdateById'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleById implements IReturn<GeneralInformationSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the general information to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationSingleResponse(); }
    public getTypeName() { return 'GeneralInformationGetSingleById'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/collection", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetCollection implements IReturn<GeneralInformationCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralInformationGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationCollectionResponse(); }
    public getTypeName() { return 'GeneralInformationGetCollection'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/create", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostCreate implements IReturn<GeneralInformationSingleResponse>
{
    /**
    * The metadata to use when creating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the general information.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationSingleResponse(); }
    public getTypeName() { return 'GeneralInformationPostCreate'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/save", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostSave implements IReturn<GeneralInformationSingleResponse>
{
    /**
    * The metadata to use when creating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The general information to save.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationSingleResponse(); }
    public getTypeName() { return 'GeneralInformationPostSave'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/validate", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostValidate implements IReturn<GeneralInformationValidationResponse>
{
    /**
    * The metadata to use when validating the general information.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The general information to validate.
    */
    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationValidationResponse(); }
    public getTypeName() { return 'GeneralInformationPostValidate'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralInformationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the general information to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationSingleResponse(); }
    public getTypeName() { return 'GeneralInformationGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - GeneralInformation
*/
// @Route("/medical-review/form/general-information/single/update", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostUpdateById implements IReturn<GeneralInformationSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralInformationPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GeneralInformationSingleResponse(); }
    public getTypeName() { return 'GeneralInformationPostUpdateById'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/id/{Id}", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingGetSingleById implements IReturn<ImagingSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImagingGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingSingleResponse(); }
    public getTypeName() { return 'ImagingGetSingleById'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/collection", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class ImagingGetCollection implements IReturn<ImagingCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ImagingGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingCollectionResponse(); }
    public getTypeName() { return 'ImagingGetCollection'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/create", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostCreate implements IReturn<ImagingSingleResponse>
{
    /**
    * The metadata to use when creating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the imaging.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingSingleResponse(); }
    public getTypeName() { return 'ImagingPostCreate'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/save", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostSave implements IReturn<ImagingSingleResponse>
{
    /**
    * The metadata to use when creating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The imaging to save.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingSingleResponse(); }
    public getTypeName() { return 'ImagingPostSave'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/validate", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingValidationResponse)", StatusCode=500)
// @DataContract
export class ImagingPostValidate implements IReturn<ImagingValidationResponse>
{
    /**
    * The metadata to use when validating the imaging.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The imaging to validate.
    */
    // @DataMember(Order=2)
    public data: Imaging;

    public constructor(init?: Partial<ImagingPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingValidationResponse(); }
    public getTypeName() { return 'ImagingPostValidate'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingGetSingleByMedicalReviewIdAndRepeat implements IReturn<ImagingSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the imaging to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ImagingGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingSingleResponse(); }
    public getTypeName() { return 'ImagingGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Imaging
*/
// @Route("/medical-review/form/imaging/single/update", "POST")
// @Api(Description="Form - Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImagingSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingPostUpdateById implements IReturn<ImagingSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ImagingPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImagingSingleResponse(); }
    public getTypeName() { return 'ImagingPostUpdateById'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistorySingleResponse(); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/collection", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/create", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostCreate implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical history.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistorySingleResponse(); }
    public getTypeName() { return 'MedicalHistoryPostCreate'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/save", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The metadata to use when creating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to save.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistorySingleResponse(); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/validate", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The metadata to use when validating the medical history.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical history to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistorySingleResponse(); }
    public getTypeName() { return 'MedicalHistoryGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - MedicalHistory
*/
// @Route("/medical-review/form/medical-history/single/update", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostUpdateById implements IReturn<MedicalHistorySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalHistorySingleResponse(); }
    public getTypeName() { return 'MedicalHistoryPostUpdateById'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleById implements IReturn<OrganFunctionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the organ function to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionSingleResponse(); }
    public getTypeName() { return 'OrganFunctionGetSingleById'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/collection", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetCollection implements IReturn<OrganFunctionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionCollectionResponse(); }
    public getTypeName() { return 'OrganFunctionGetCollection'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/create", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostCreate implements IReturn<OrganFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the organ function.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionSingleResponse(); }
    public getTypeName() { return 'OrganFunctionPostCreate'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/save", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostSave implements IReturn<OrganFunctionSingleResponse>
{
    /**
    * The metadata to use when creating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function to save.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionSingleResponse(); }
    public getTypeName() { return 'OrganFunctionPostSave'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/validate", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostValidate implements IReturn<OrganFunctionValidationResponse>
{
    /**
    * The metadata to use when validating the organ function.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function to validate.
    */
    // @DataMember(Order=2)
    public data: OrganFunction;

    public constructor(init?: Partial<OrganFunctionPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionValidationResponse(); }
    public getTypeName() { return 'OrganFunctionPostValidate'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the organ function to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionSingleResponse(); }
    public getTypeName() { return 'OrganFunctionGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - OrganFunction
*/
// @Route("/medical-review/form/organ-function/single/update", "POST")
// @Api(Description="Form - OrganFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionPostUpdateById implements IReturn<OrganFunctionSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionSingleResponse(); }
    public getTypeName() { return 'OrganFunctionPostUpdateById'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetSingleById implements IReturn<OrganFunctionTreatmentSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the organ function treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentGetSingleById'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/collection", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetCollection implements IReturn<OrganFunctionTreatmentCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentCollectionResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentGetCollection'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/create", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostCreate implements IReturn<OrganFunctionTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the organ function treatment.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentPostCreate'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/save", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostSave implements IReturn<OrganFunctionTreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function treatment to save.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentPostSave'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/validate", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostValidate implements IReturn<OrganFunctionTreatmentValidationResponse>
{
    /**
    * The metadata to use when validating the organ function treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The organ function treatment to validate.
    */
    // @DataMember(Order=2)
    public data: OrganFunctionTreatment;

    public constructor(init?: Partial<OrganFunctionTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentValidationResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentPostValidate'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<OrganFunctionTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the organ function treatment to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - OrganFunctionTreatment
*/
// @Route("/medical-review/form/organ-function-treatment/single/update", "POST")
// @Api(Description="Form - OrganFunctionTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OrganFunctionTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class OrganFunctionTreatmentPostUpdateById implements IReturn<OrganFunctionTreatmentSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OrganFunctionTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new OrganFunctionTreatmentSingleResponse(); }
    public getTypeName() { return 'OrganFunctionTreatmentPostUpdateById'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/single/id/{Id}", "GET")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalGetSingleById implements IReturn<RecurrenceSurvivalSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the recurrence survival to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<RecurrenceSurvivalGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalSingleResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalGetSingleById'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/collection", "GET")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalCollectionResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalGetCollection implements IReturn<RecurrenceSurvivalCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<RecurrenceSurvivalGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalCollectionResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalGetCollection'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/create", "POST")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalPostCreate implements IReturn<RecurrenceSurvivalSingleResponse>
{
    /**
    * The metadata to use when creating the recurrence survival.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the recurrence survival.
    */
    // @DataMember(Order=2)
    public data: RecurrenceSurvival;

    public constructor(init?: Partial<RecurrenceSurvivalPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalSingleResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalPostCreate'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/save", "POST")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalPostSave implements IReturn<RecurrenceSurvivalSingleResponse>
{
    /**
    * The metadata to use when creating the recurrence survival.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The recurrence survival to save.
    */
    // @DataMember(Order=2)
    public data: RecurrenceSurvival;

    public constructor(init?: Partial<RecurrenceSurvivalPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalSingleResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalPostSave'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/validate", "POST")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalValidationResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalPostValidate implements IReturn<RecurrenceSurvivalValidationResponse>
{
    /**
    * The metadata to use when validating the recurrence survival.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The recurrence survival to validate.
    */
    // @DataMember(Order=2)
    public data: RecurrenceSurvival;

    public constructor(init?: Partial<RecurrenceSurvivalPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalValidationResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalPostValidate'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalGetSingleByMedicalReviewIdAndRepeat implements IReturn<RecurrenceSurvivalSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the recurrence survival to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<RecurrenceSurvivalGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalSingleResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - RecurrenceSurvival
*/
// @Route("/medical-review/form/recurrence-survival/single/update", "POST")
// @Api(Description="Form - RecurrenceSurvival")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RecurrenceSurvivalSingleResponse)", StatusCode=500)
// @DataContract
export class RecurrenceSurvivalPostUpdateById implements IReturn<RecurrenceSurvivalSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<RecurrenceSurvivalPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecurrenceSurvivalSingleResponse(); }
    public getTypeName() { return 'RecurrenceSurvivalPostUpdateById'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/id/{Id}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleById implements IReturn<SurgerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgery to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryGetSingleById'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/collection", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetCollection implements IReturn<SurgeryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryCollectionResponse(); }
    public getTypeName() { return 'SurgeryGetCollection'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/create", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostCreate implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryPostCreate'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/save", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostSave implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to save.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryPostSave'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/validate", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostValidate implements IReturn<SurgeryValidationResponse>
{
    /**
    * The metadata to use when validating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to validate.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryValidationResponse(); }
    public getTypeName() { return 'SurgeryPostValidate'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgerySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the surgery to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - Surgery
*/
// @Route("/medical-review/form/surgery/single/update", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostUpdateById implements IReturn<SurgerySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryPostUpdateById'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/single/id/{Id}", "GET")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyGetSingleById implements IReturn<SurgicalPathologySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the surgical pathology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgicalPathologyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologySingleResponse(); }
    public getTypeName() { return 'SurgicalPathologyGetSingleById'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/collection", "GET")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyGetCollection implements IReturn<SurgicalPathologyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgicalPathologyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologyCollectionResponse(); }
    public getTypeName() { return 'SurgicalPathologyGetCollection'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/create", "POST")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyPostCreate implements IReturn<SurgicalPathologySingleResponse>
{
    /**
    * The metadata to use when creating the surgical pathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgical pathology.
    */
    // @DataMember(Order=2)
    public data: SurgicalPathology;

    public constructor(init?: Partial<SurgicalPathologyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologySingleResponse(); }
    public getTypeName() { return 'SurgicalPathologyPostCreate'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/save", "POST")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyPostSave implements IReturn<SurgicalPathologySingleResponse>
{
    /**
    * The metadata to use when creating the surgical pathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgical pathology to save.
    */
    // @DataMember(Order=2)
    public data: SurgicalPathology;

    public constructor(init?: Partial<SurgicalPathologyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologySingleResponse(); }
    public getTypeName() { return 'SurgicalPathologyPostSave'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/validate", "POST")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologyValidationResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyPostValidate implements IReturn<SurgicalPathologyValidationResponse>
{
    /**
    * The metadata to use when validating the surgical pathology.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgical pathology to validate.
    */
    // @DataMember(Order=2)
    public data: SurgicalPathology;

    public constructor(init?: Partial<SurgicalPathologyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologyValidationResponse(); }
    public getTypeName() { return 'SurgicalPathologyPostValidate'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgicalPathologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the surgical pathology to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgicalPathologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologySingleResponse(); }
    public getTypeName() { return 'SurgicalPathologyGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - SurgicalPathology
*/
// @Route("/medical-review/form/surgical-pathology/single/update", "POST")
// @Api(Description="Form - SurgicalPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalPathologyPostUpdateById implements IReturn<SurgicalPathologySingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgicalPathologyPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgicalPathologySingleResponse(); }
    public getTypeName() { return 'SurgicalPathologyPostUpdateById'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/single/id/{Id}", "GET")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsGetSingleById implements IReturn<TumourMeasurementsSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the tumour measurements to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourMeasurementsGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsSingleResponse(); }
    public getTypeName() { return 'TumourMeasurementsGetSingleById'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/collection", "GET")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsGetCollection implements IReturn<TumourMeasurementsCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TumourMeasurementsGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsCollectionResponse(); }
    public getTypeName() { return 'TumourMeasurementsGetCollection'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/create", "POST")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsPostCreate implements IReturn<TumourMeasurementsSingleResponse>
{
    /**
    * The metadata to use when creating the tumour measurements.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the tumour measurements.
    */
    // @DataMember(Order=2)
    public data: TumourMeasurements;

    public constructor(init?: Partial<TumourMeasurementsPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsSingleResponse(); }
    public getTypeName() { return 'TumourMeasurementsPostCreate'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/save", "POST")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsPostSave implements IReturn<TumourMeasurementsSingleResponse>
{
    /**
    * The metadata to use when creating the tumour measurements.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The tumour measurements to save.
    */
    // @DataMember(Order=2)
    public data: TumourMeasurements;

    public constructor(init?: Partial<TumourMeasurementsPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsSingleResponse(); }
    public getTypeName() { return 'TumourMeasurementsPostSave'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/validate", "POST")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsValidationResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsPostValidate implements IReturn<TumourMeasurementsValidationResponse>
{
    /**
    * The metadata to use when validating the tumour measurements.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The tumour measurements to validate.
    */
    // @DataMember(Order=2)
    public data: TumourMeasurements;

    public constructor(init?: Partial<TumourMeasurementsPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsValidationResponse(); }
    public getTypeName() { return 'TumourMeasurementsPostValidate'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsGetSingleByMedicalReviewIdAndRepeat implements IReturn<TumourMeasurementsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    /**
    * The id of the medical review.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    /**
    * The repeat of the tumour measurements to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TumourMeasurementsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsSingleResponse(); }
    public getTypeName() { return 'TumourMeasurementsGetSingleByMedicalReviewIdAndRepeat'; }
}

/**
* Form - TumourMeasurements
*/
// @Route("/medical-review/form/tumour-measurements/single/update", "POST")
// @Api(Description="Form - TumourMeasurements")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourMeasurementsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourMeasurementsPostUpdateById implements IReturn<TumourMeasurementsSingleResponse>, IFormPostUpdateById
{
    /**
    * The id of the medical review form.
    */
    // @DataMember(Order=1)
    public id?: number;

    /**
    * The additional metadata for the medical review.
    */
    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TumourMeasurementsPostUpdateById>) { (Object as any).assign(this, init); }
    public createResponse() { return new TumourMeasurementsSingleResponse(); }
    public getTypeName() { return 'TumourMeasurementsPostUpdateById'; }
}

// @Route("/medical-review/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/medical-review/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadFile'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupCollectionResponse(); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to save.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostSave'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    /**
    * The metadata to use when validating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to validate.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupValidationResponse(); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coordinating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to save.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    /**
    * The metadata to use when validating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to validate.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
}

/**
* Country
*/
// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryGetSingleById'; }
}

/**
* Country
*/
// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryCollectionResponse(); }
    public getTypeName() { return 'CountryGetCollection'; }
}

/**
* Country
*/
// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostCreate'; }
}

/**
* Country
*/
// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to save.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostSave'; }
}

/**
* Country
*/
// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    /**
    * The metadata to use when validating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to validate.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryValidationResponse(); }
    public getTypeName() { return 'CountryPostValidate'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionCollectionResponse(); }
    public getTypeName() { return 'InstitutionGetCollection'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostCreate'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to save.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostSave'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    /**
    * The metadata to use when validating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to validate.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionValidationResponse(); }
    public getTypeName() { return 'InstitutionPostValidate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleById'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientCollectionResponse(); }
    public getTypeName() { return 'PatientGetCollection'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostCreate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostSave'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientValidationResponse(); }
    public getTypeName() { return 'PatientPostValidate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
}

// @Route("/medical-review/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostHasPermissionsResponse(); }
    public getTypeName() { return 'PostHasPermissions'; }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the medical review definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
}

/**
* Medical Review Definition
*/
// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    /**
    * The ID of the medical review definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
}

/**
* Query Recipient
*/
// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    /**
    * The ID of the query recipient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryRecipientSingleResponse(); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
}

/**
* Query Recipient
*/
// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the reviewer to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReviewerSingleResponse(); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReviewerCollectionResponse(); }
    public getTypeName() { return 'ReviewerGetCollection'; }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    /**
    * The metadata to use when creating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the reviewer.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReviewerSingleResponse(); }
    public getTypeName() { return 'ReviewerPostCreate'; }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    /**
    * The metadata to use when creating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The reviewer to save.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReviewerSingleResponse(); }
    public getTypeName() { return 'ReviewerPostSave'; }
}

/**
* Reviewer
*/
// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    /**
    * The metadata to use when validating the reviewer.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The reviewer to validate.
    */
    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReviewerValidationResponse(); }
    public getTypeName() { return 'ReviewerPostValidate'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewSingleResponse(); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewSingleResponse(); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical review to save.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewSingleResponse(); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    /**
    * The metadata to use when validating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The medical review to validate.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewValidationResponse(); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewSingleResponse(); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    /**
    * The Study Number of the medical review to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the medical review.
    */
    // @DataMember(Order=2)
    public data: MedicalReview;

    /**
    * The options to use when creating the medical review.
    */
    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    /**
    * The metadata to use when creating the medical review.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the medical review.
    */
    // @DataMember(Order=2)
    public medicalReviewId?: number;

    /**
    * The options to use when creating the medical review.
    */
    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
}

/**
* Medical Review
*/
// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    /**
    * The metadata to use when validating the medical review forms.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The Id of the medical review to medical review.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
}

/**
* Query
*/
// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the query to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryGetSingleById'; }
}

/**
* Query
*/
// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryGetCollection'; }
}

/**
* Query
*/
// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the query.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryPostCreate'; }
}

/**
* Query
*/
// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new QuerySingleResponse(); }
    public getTypeName() { return 'QueryPostSave'; }
}

/**
* Query
*/
// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    /**
    * The metadata to use when validating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to validate.
    */
    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryValidationResponse(); }
    public getTypeName() { return 'QueryPostValidate'; }
}

/**
* Query
*/
// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    /**
    * The ID of the medical review to retrieve query for.
    */
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
}

/**
* Query
*/
// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    /**
    * The metadata to use when creating the query.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The query to save.
    */
    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new QueryCollectionResponse(); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the updatedformproperty to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the updatedformproperty.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    /**
    * The metadata to use when creating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to save.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
}

/**
* UpdatedFormProperty
*/
// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    /**
    * The metadata to use when validating the updatedformproperty.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The updatedformproperty to validate.
    */
    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
}

