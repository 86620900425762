import React, { FunctionComponent, ReactNode } from 'react';
import {  getParentPropertyPath, IValidationError } from '@ngt/forms';
import { Typography } from '@mui/material';
import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';

interface IValueWithReasonAndSpecificationDisplayProps extends IInputRenderProps<string, IValidationError>
{
    reasonName: string;
    reasonPrependParentName?: boolean;
    reasonTableCell?: boolean;
    specificationName: string;
    specificationPrependParentName?: boolean;
    specificationTableCell?: boolean;
    accessor?: (value: any) => ReactNode;
    specificationAccessor?: (value: any) => ReactNode;
    reasonAccessor?: (value: any) => ReactNode;
}



const subscription = { value: true };

const ValueWithReasonAndSpecificationDisplay: FunctionComponent<IValueWithReasonAndSpecificationDisplayProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { ...restInputActions } },
    specificationName,
    specificationPrependParentName,
    specificationTableCell,
    reasonName,
    reasonPrependParentName,
    reasonTableCell,
    accessor,
    reasonAccessor,
    specificationAccessor,
    ...rest
}) => {
    const parentContext = React.useContext(FieldContext);

    const computedSpecificationName = React.useMemo(() => {
        if (parentContext?.name && specificationPrependParentName === true) {
            const parentName = specificationTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (specificationName.startsWith('[')) {
                return `${parentName}${specificationName}`;
            }

            return `${parentName}.${specificationName}`;
        }

        return specificationName;
    }, [parentContext?.name, specificationName, specificationPrependParentName, specificationTableCell]);

    const computedReasonName = React.useMemo(() => {
        if (parentContext?.name && reasonPrependParentName === true) {
            const parentName = reasonTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (reasonName.startsWith('[')) {
                return `${parentName}${reasonName}`;
            }

            return `${parentName}.${reasonName}`;
        }

        return reasonName;
    }, [parentContext?.name, reasonName, reasonPrependParentName, reasonTableCell]);

    const { state: { value: specificationValue } } = useScopedField<string, IValidationError>(computedSpecificationName, subscription, false);
    const { state: { value: reasonValue } } = useScopedField<string, IValidationError>(computedReasonName, subscription, false);

    const calculatedSpecificationValue = specificationValue ? <> &ndash; {specificationAccessor ? specificationAccessor(specificationValue) : specificationValue}</> : null;

    const calculatedReasonValue = reasonValue ? <> &ndash; {reasonAccessor ? reasonAccessor(reasonValue) : reasonValue}</> : null;

    return (
        <Typography>
            {accessor? accessor(value) : value}{calculatedReasonValue}{calculatedSpecificationValue}
        </Typography>
    )
};

export default ValueWithReasonAndSpecificationDisplay;
