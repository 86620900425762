import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField } from '@ngt/forms-medical-review';
import { GroupedFields, FormContext, FieldProvider, NumericDisplay, Input, BooleanDisplay, TextDisplay, DateDisplay, HeadingDisplay } from '@ngt/forms';
import { ClinicalTumourMeasurementCycle, DiagnosticTumourMeasurementStage, TumourMeasurements as TumourMeasurementsDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { useContext } from 'react';
import CoalesceDisplay from '../../components/form/input/display/CoalesceDisplay';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { BasicAlert } from '@ngt/forms-trials';
import FormDates from '../../components/form/FormDates';

interface ITumourMeasurementsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        overflowX: 'auto'
    },
    table: {
        minWidth: '100%',
        padding: theme.spacing(2, 2, 2),
    },
}));

const hasClinicalTumorMeasurements: ICondition<TumourMeasurementsDto> = ({ formState: { value } = {} }) => !!value?.clinicalTumourMeasurements && value?.clinicalTumourMeasurements.length > 0;
const hasNoClinicalTumorMeasurements: ICondition<TumourMeasurementsDto> = ({ formState: { value } = {} }) => !value?.clinicalTumourMeasurements || value?.clinicalTumourMeasurements.length === 0;

const hasDiagnosticTumorMeasurements: ICondition<TumourMeasurementsDto> = ({ formState: { value } = {} }) => !!value?.diagnosticTumourMeasurements && value?.diagnosticTumourMeasurements.length > 0;
const hasNoDiagnosticTumorMeasurements: ICondition<TumourMeasurementsDto> = ({ formState: { value } = {} }) => !value?.diagnosticTumourMeasurements || value?.diagnosticTumourMeasurements.length === 0;

const conditionSubscription = { value: true };

const longestDiameterBoolAccessor = (value: boolean) => value ? "Not Measurable" : null;
const longestDiameterDataAccessor = (value: string) => value ? <>{value} <Typography color="textSecondary" variant="caption" component="span"> mm</Typography></> : null;

const clinicalTumourMeasurementCycleColumns: IGroupedSubformGridCell<ClinicalTumourMeasurementCycle>[] = [
    {
        name: 'cycleName',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'clincalMeasurementsCompleted',
        content: (
            <Input component={BooleanDisplay} />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'longestDiameterValue',
        content: (
            <Input
                component={CoalesceDisplay}
                ifNullName="longestDiameter"
                ifNullAccessor={longestDiameterBoolAccessor}
                accessor={longestDiameterDataAccessor}
                ifNullPrependParentName
                ifNullTableCell
            />
        )
    },
    {
        name: 'evidenceOfProgression',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const CliniclMeasurements: FunctionComponent = () => {
    const { data: form } = useContext(FormContext);
    const tumourMeasurements = form as TumourMeasurementsDto;

    const { classes } = useStyles();

    return (
        <FieldProvider name="clinicalTumourMeasurements" autoRegister={false}>
            {
                tumourMeasurements?.clinicalTumourMeasurements?.map((ctm, i) => {
                    return (
                        <FieldProvider key={i} name={`[${i}]`}>
                            <InputOnlyField
                                name="breastMassNumber"
                                component={HeadingDisplay}
                                varient="h5"
                                heading="Breast Mass Number"
                                className={classes.subtitle}
                            />
                            <div className={classes.table}>
                                <GroupedSubformGrid
                                    type={ClinicalTumourMeasurementCycle}
                                    name="clinicalTumourMeasurementCycles"
                                    columns={clinicalTumourMeasurementCycleColumns}
                                    disabled
                                />
                            </div>
                        </FieldProvider>
                        )
                })
            }
        </FieldProvider>
    );
};

const diagnosticTumourMeasurementStageColumns: IGroupedSubformGridCell<DiagnosticTumourMeasurementStage>[] = [
    {
        name: 'stage',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dimension1',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'dimension2',
        content: (
            <Input component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    }
];

const DiagnosticMeasurements: FunctionComponent = () => {
    const { data: form } = useContext(FormContext);
    const tumourMeasurements = form as TumourMeasurementsDto;

    const { classes } = useStyles();

    return (
        <FieldProvider name="diagnosticTumourMeasurements" autoRegister={false}>
            {
                tumourMeasurements?.diagnosticTumourMeasurements?.map((ctm, i) => {
                    return (
                        <FieldProvider key={i} name={`[${i}]`}>
                            <InputOnlyField
                                name="breastLesionNumber"
                                component={HeadingDisplay}
                                varient="h5"
                                heading="Breast Lesion Number"
                                className={classes.subtitle}
                            />
                            <div className={classes.table}>
                                <GroupedSubformGrid
                                    type={DiagnosticTumourMeasurementStage}
                                    name="diagnosticTumourMeasurementStages"
                                    columns={diagnosticTumourMeasurementStageColumns}
                                    disabled
                                />
                            </div>
                        </FieldProvider>
                    )
                })
            }
        </FieldProvider>
    );
};

const TumourMeasurements: FunctionComponent<ITumourMeasurementsProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Clinical Tumour Measurements
                    </Typography>
                    <Condition
                        type={TumourMeasurementsDto}
                        condition={hasClinicalTumorMeasurements}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <CliniclMeasurements />
                    </Condition>
                    <Condition
                        type={TumourMeasurementsDto}
                        condition={hasNoClinicalTumorMeasurements}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Clinical Tumour Measurements associated with this patient"
                                description="There are no Clinical Tumour Measurements associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Ultrasound Tumour Measurements
                    </Typography>
                    <Condition
                        type={TumourMeasurementsDto}
                        condition={hasDiagnosticTumorMeasurements}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <DiagnosticMeasurements />
                    </Condition>
                    <Condition
                        type={TumourMeasurementsDto}
                        condition={hasNoDiagnosticTumorMeasurements}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Diagnostic Tumour Measurements associated with this patient"
                                description="There are no Diagnostic Tumour Measurements associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default TumourMeasurements;
