import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { TextDisplay, Input, DateDisplay } from '@ngt/forms';
import { ConcomitantMedicationItem, ConcomitantMedications as ConcomitantMedicationsDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { BasicAlert } from '@ngt/forms-trials';
import FormDates from '../../components/form/FormDates';

interface IConcomitantMedicationsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const hasNoMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !value?.concomitantMedicationItems || value?.concomitantMedicationItems?.length === 0;
const hasMedications: ICondition<ConcomitantMedicationsDto> = ({ formState: { value } = {} }) => !!value?.concomitantMedicationItems && value?.concomitantMedicationItems?.length > 0;

const conditionSubscription = { value: true };

const concomitantMedicationColumns: IGroupedSubformGridCell<ConcomitantMedicationItem>[] = [
    {
        name: 'agentName',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dose',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'units',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'route',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'indication',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dateStarted',
        content: (
            <Input component={DateDisplay} />
        )
    },
    {
        name: 'dateStopped',
        content: (
            <Input component={DateDisplay} />
        )
    }
];

const ConcomitantMedications: FunctionComponent<IConcomitantMedicationsProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Condition
                    type={ConcomitantMedicationsDto}
                    condition={hasMedications}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedSubformGrid
                        type={ConcomitantMedicationItem}
                        name="concomitantMedicationItems"
                        columns={concomitantMedicationColumns}
                        disabled
                    />
                </Condition>
                <Condition
                    type={ConcomitantMedicationsDto}
                    condition={hasNoMedications}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Concomitant Medications"
                            description="There is no Concomitant Medications associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default ConcomitantMedications;
