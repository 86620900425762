import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, OpenClinicaFormDisplay, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { GroupedFields, TextDisplay, Input } from '@ngt/forms';
import { Biochemistry, Haematology, ThyroidFunction, Urinalysis } from '../../apis/dtos';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import IfElseDisplay from '../../components/form/input/display/IfElseDisplay';
import { DateTime } from 'luxon';
import BiochemistryDisplay from '../../components/form/input/display/BiochemistryDisplay';
import FormDates from '../../components/form/FormDates';

interface IOrganFunctionTreatmentProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;
const notAdministeredAccessor = () => 'Not Done';

const haematologyCycleColumns: IGroupedSubformGridCell<Haematology>[] = [
    {
        name: 'cycle',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentDone',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName=""
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        )
    },
    {
        name: 'haemoglobinValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="haemoglobinLln"
                ulnName="haemoglobinUln"
                signName="haemoglobinSign"
                unitName="haemoglobinUnits"
                unitSpecName="haemoglobinUnitsSpecify"
                notDoneName="haemoglobin"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'haemoglobinLln'
                },
                {
                    name: 'haemoglobinUln'
                },
                {
                    name: 'haemoglobinSign'
                },
                {
                    name: 'haemoglobinUnits'
                },
                {
                    name: 'haemoglobinUnitsSpecify'
                },
                {
                    name: 'haemoglobin'
                }
            ]
        }
    },
    {
        name: 'neutrophilsValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="neutrophilsLln"
                ulnName="neutrophilsUln"
                signName="neutrophilsSign"
                unitName="neutrophilsUnits"
                unitSpecName="neutrophilsUnitsSpecify"
                notDoneName="neutrophils"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'neutrophilsLln'
                },
                {
                    name: 'neutrophilsUln'
                },
                {
                    name: 'neutrophilsSign'
                },
                {
                    name: 'neutrophilsUnits'
                },
                {
                    name: 'neutrophilsUnitsSpecify'
                },
                {
                    name: 'neutrophils'
                }
            ]
        }
    },
    {
        name: 'plateletsValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="plateletsLln"
                ulnName="plateletsUln"
                signName="plateletsSign"
                unitName="plateletsUnits"
                unitSpecName="plateletsUnitsSpecify"
                notDoneName="platelets"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'plateletsLln'
                },
                {
                    name: 'plateletsUln'
                },
                {
                    name: 'plateletsSign'
                },
                {
                    name: 'plateletsUnits'
                },
                {
                    name: 'plateletsUnitsSpecify'
                },
                {
                    name: 'platelets'
                }
            ]
        }
    },
    {
        name: 'wbcValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="wbcLln"
                ulnName="wbcUln"
                signName="wbcSign"
                unitName="wbcUnits"
                unitSpecName="wbcUnitsSpecify"
                notDoneName="wbc"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'wbcLln'
                },
                {
                    name: 'wbcUln'
                },
                {
                    name: 'wbcSign'
                },
                {
                    name: 'wbcUnits'
                },
                {
                    name: 'wbcUnitsSpecify'
                },
                {
                    name: 'wbc'
                }
            ]
        }
    }
];

const biochemistryCycleColumns: IGroupedSubformGridCell<Biochemistry>[] = [
    {
        name: 'cycle',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentDone',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName=""
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        )
    },
    {
        name: 'creatinineValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="creatinineLln"
                ulnName="creatinineUln"
                signName="creatinineSign"
                unitName="creatinineUnits"
                unitSpecName="creatinineUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'creatinineLln'
                },
                {
                    name: 'creatinineUln'
                },
                {
                    name: 'creatinineSign'
                },
                {
                    name: 'creatinineUnits'
                },
                {
                    name: 'creatinineUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'egfrValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="egfrLln"
                ulnName="egfrUln"
                signName="egfrSign"
                unitName="egfrUnits"
                unitSpecName="egfrUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'egfrLln'
                },
                {
                    name: 'egfrUln'
                },
                {
                    name: 'egfrSign'
                },
                {
                    name: 'egfrUnits'
                },
                {
                    name: 'egfrUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'asatValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="asatLln"
                ulnName="asatUln"
                signName="asatSign"
                unitName="asatUnits"
                unitSpecName="asatUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'asatLln'
                },
                {
                    name: 'asatUln'
                },
                {
                    name: 'asatSign'
                },
                {
                    name: 'asatUnits'
                },
                {
                    name: 'asatUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'alatValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="alatLln"
                ulnName="alatUln"
                signName="alatSign"
                unitName="alatUnits"
                unitSpecName="alatUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'alatLln'
                },
                {
                    name: 'alatUln'
                },
                {
                    name: 'alatSign'
                },
                {
                    name: 'alatUnits'
                },
                {
                    name: 'alatUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'ggtValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="ggtLln"
                ulnName="ggtUln"
                signName="ggtSign"
                unitName="ggtUnits"
                unitSpecName="ggtUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ggtLln'
                },
                {
                    name: 'ggtUln'
                },
                {
                    name: 'ggtSign'
                },
                {
                    name: 'ggtUnits'
                },
                {
                    name: 'ggtUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'ldhValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="ldhLln"
                ulnName="ldhUln"
                signName="ldhSign"
                unitName="ldhUnits"
                unitSpecName="ldhUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ldhLln'
                },
                {
                    name: 'ldhUln'
                },
                {
                    name: 'ldhSign'
                },
                {
                    name: 'ldhUnits'
                },
                {
                    name: 'ldhUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'alpValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="alpLln"
                ulnName="alpUln"
                signName="alpSign"
                unitName="alpUnits"
                unitSpecName="alpUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'alpLln'
                },
                {
                    name: 'alpUln'
                },
                {
                    name: 'alpSign'
                },
                {
                    name: 'alpUnits'
                },
                {
                    name: 'alpUnitsSpecify'
                }
            ]
        }
    }
];

const biochemistryCycleColumns2: IGroupedSubformGridCell<Biochemistry>[] = [
    {
        name: 'cycle',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'totalBilirubinValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="totalBilirubinLln"
                ulnName="totalBilirubinUln"
                signName="totalBilirubinSign"
                unitName="totalBilirubinUnits"
                unitSpecName="totalBilirubinUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'totalBilirubinLln'
                },
                {
                    name: 'totalBilirubinUln'
                },
                {
                    name: 'totalBilirubinSign'
                },
                {
                    name: 'totalBilirubinUnits'
                },
                {
                    name: 'totalBilirubinUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'sodiumValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="sodiumLln"
                ulnName="sodiumUln"
                signName="sodiumSign"
                unitName="sodiumUnits"
                unitSpecName="sodiumUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'sodiumLln'
                },
                {
                    name: 'sodiumUln'
                },
                {
                    name: 'sodiumSign'
                },
                {
                    name: 'sodiumUnits'
                },
                {
                    name: 'sodiumUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'potassiumValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="potassiumLln"
                ulnName="potassiumUln"
                signName="potassiumSign"
                unitName="potassiumUnits"
                unitSpecName="potassiumUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'potassiumLln'
                },
                {
                    name: 'potassiumUln'
                },
                {
                    name: 'potassiumSign'
                },
                {
                    name: 'potassiumUnits'
                },
                {
                    name: 'potassiumUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'calciumValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="calciumLln"
                ulnName="calciumUln"
                signName="calciumSign"
                unitName="calciumUnits"
                unitSpecName="calciumUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'calciumLln'
                },
                {
                    name: 'calciumUln'
                },
                {
                    name: 'calciumSign'
                },
                {
                    name: 'calciumUnits'
                },
                {
                    name: 'calciumUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'ureaValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="ureaLln"
                ulnName="ureaUln"
                signName="ureaSign"
                unitName="ureaUnits"
                unitSpecName="ureaUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'ureaLln'
                },
                {
                    name: 'ureaUln'
                },
                {
                    name: 'ureaSign'
                },
                {
                    name: 'ureaUnits'
                },
                {
                    name: 'ureaUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'amylaseValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="amylaseLln"
                ulnName="amylaseUln"
                signName="amylaseSign"
                unitName="amylaseUnits"
                unitSpecName="amylaseUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'amylaseLln'
                },
                {
                    name: 'amylaseUln'
                },
                {
                    name: 'amylaseSign'
                },
                {
                    name: 'amylaseUnits'
                },
                {
                    name: 'amylaseUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'lipaseValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="lipaseLln"
                ulnName="lipaseUln"
                signName="lipaseSign"
                unitName="lipaseUnits"
                unitSpecName="lipaseUnitsSpecify"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'lipaseLln'
                },
                {
                    name: 'lipaseUln'
                },
                {
                    name: 'lipaseSign'
                },
                {
                    name: 'lipaseUnits'
                },
                {
                    name: 'lipaseUnitsSpecify'
                }
            ]
        }
    },
    {
        name: 'cortisolValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="cortisolLln"
                ulnName="cortisolUln"
                signName="cortisolSign"
                unitName="cortisolUnits"
                unitSpecName="cortisolUnitsSpecify"
                notDoneName="cortisol"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'cortisolLln'
                },
                {
                    name: 'cortisolUln'
                },
                {
                    name: 'cortisolSign'
                },
                {
                    name: 'cortisolUnits'
                },
                {
                    name: 'cortisolUnitsSpecify'
                },
                {
                    name: 'cortisol'
                }
            ]
        }
    }
];

const thyroidFunctionCycleColumns: IGroupedSubformGridCell<ThyroidFunction>[] = [
    {
        name: 'cycle',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'assessmentDone',
        width: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName=""
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                }
            ]
        }
    },
    {
        name: 'tshValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="tshLln"
                ulnName="tshUln"
                signName="tshSign"
                unitName="tshUnits"
                unitSpecName="tshUnitsSpecify"
                notDoneName="tsh"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'tshLln'
                },
                {
                    name: 'tshUln'
                },
                {
                    name: 'tshSign'
                },
                {
                    name: 'tshUnits'
                },
                {
                    name: 'tshUnitsSpecify'
                },
                {
                    name: 'tsh'
                }
            ]
        }
    },
    {
        name: 't3Value',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="t3Lln"
                ulnName="t3Uln"
                signName="t3Sign"
                unitName="t3Units"
                unitSpecName="t3UnitsSpecify"
                notDoneName="t3"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't3Lln'
                },
                {
                    name: 't3Uln'
                },
                {
                    name: 't3Sign'
                },
                {
                    name: 't3Units'
                },
                {
                    name: 't3UnitsSpecify'
                },
                {
                    name: 't3'
                }
            ]
        }
    },
    {
        name: 't4Value',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="t4Lln"
                ulnName="t4Uln"
                signName="t4Sign"
                unitName="t4Units"
                unitSpecName="t4UnitsSpecify"
                notDoneName="t4"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 't4Lln'
                },
                {
                    name: 't4Uln'
                },
                {
                    name: 't4Sign'
                },
                {
                    name: 't4Units'
                },
                {
                    name: 't4UnitsSpecify'
                },
                {
                    name: 't4'
                }
            ]
        }
    },
    {
        name: 'acthValue',
        width: 300,
        content: (
            <Input
                component={BiochemistryDisplay}
                llnName="acthLln"
                ulnName="acthUln"
                signName="acthSign"
                unitName="acthUnits"
                unitSpecName="acthUnitsSpecify"
                notDoneName="acth"
                prependParentName
                tableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'acthLln'
                },
                {
                    name: 'acthUln'
                },
                {
                    name: 'acthSign'
                },
                {
                    name: 'acthUnits'
                },
                {
                    name: 'acthUnitsSpecify'
                },
                {
                    name: 'acth'
                }
            ]
        }
    }
];

const urinalysisCycleColumns: IGroupedSubformGridCell<Urinalysis>[] = [
    {
        name: 'cycle',
        minWidth: 150,
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="120px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        )
    },
    {
        name: 'urinalysisDone',
        minWidth: 300,
        content: (
            <Input
                component={IfElseDisplay}
                ifName="date"
                elseName=""
                ifPrependParentName
                ifTableCell
                elsePrependParentName
                elseTableCell
                ifAccessor={dateAccessor}
                elseAccessor={notAdministeredAccessor}
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'date'
                }
            ]
        }
    },
    {
        name: 'result',
        minWidth: 300,
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const OrganFunctionTreatment: FunctionComponent<IOrganFunctionTreatmentProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Haematology
                    </Typography>
                    <GroupedSubformGrid
                        type={Haematology}
                        name="haematologyCycles"
                        columns={haematologyCycleColumns}
                        disabled
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Biochemistry
                    </Typography>
                    <GroupedSubformGrid
                        type={Biochemistry}
                        name="biochemistryCycles"
                        columns={biochemistryCycleColumns}
                        disabled
                    />
                    
                    <Typography variant="h4" className={classes.subtitle}>
                        Biochemistry cont.
                    </Typography>
                    <GroupedSubformGrid
                        type={Biochemistry}
                        name="biochemistryCycles"
                        columns={biochemistryCycleColumns2}
                        disabled
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Thyroid Function Tests
                    </Typography>
                    <GroupedSubformGrid
                        type={ThyroidFunction}
                        name="thyroidFunctionCycles"
                        columns={thyroidFunctionCycleColumns}
                        disabled
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Urinalysis
                    </Typography>
                    <GroupedSubformGrid
                        type={Urinalysis}
                        name="urinalysisCycles"
                        columns={urinalysisCycleColumns}
                        disabled
                    />
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default OrganFunctionTreatment;
