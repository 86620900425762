import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, DateDisplay, TextDisplay, NumericDisplay, BooleanDisplay, Input } from '@ngt/forms';
import { SlnbResult, Surgery as SurgeryDto, SurgeryBreastUltrasound, SurgeryType } from '../../apis/dtos';
import { ConditionMode } from '@ngt/forms-core';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface ISurgeryProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasUltrasound: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.breastUltrasound;
const hasNoUltrasound: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.breastUltrasound;

const hasSuspiciousNodes: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.suspiciousAxillaryNodes;

const hasSurgicalAssessment: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgicalAssessment;
const hasNoSurgicalAssessment: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.surgicalAssessment;

const hasSurgery: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.surgeryCompleted && !!value?.surgeryTypes && value?.surgeryTypes.length > 0;

const slnbPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.slnb;
const slnbNotPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.slnb;

const dissectionPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !!value?.axillaryDissectionPerformed;
const dissectionNotPerformed: ICondition<SurgeryDto> = ({ formState: { value } = {} }) => !value?.axillaryDissectionPerformed;

const conditionSubscription = { value: true };

const surgeryBreastUltrasoundColumns: IGroupedSubformGridCell<SurgeryBreastUltrasound>[] = [
    {
        name: 'breastLesionNumber',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dimension1',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'dimension2',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mm</Typography>
                }
            />
        )
    },
    {
        name: 'response',
        content: (
            <Input
                component={TextDisplay}
            />
        )
    }
];

const surgeryTypeColumns: IGroupedSubformGridCell<SurgeryType>[] = [
    {
        name: 'type',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'date',
        width: '60%' as any,
        content: (
            <Input component={DateDisplay} />
        )
    }
];

const slnbResultsColumns: IGroupedSubformGridCell<SlnbResult>[] = [
    {
        name: 'nodeNumber',
        content: (
            <Input component={NumericDisplay} />
        )
    },
    {
        name: 'location',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'hot',
        content: (
            <Input component={BooleanDisplay} />
        )
    },
    {
        name: 'blue',
        content: (
            <Input component={BooleanDisplay} />
        )
    },
    {
        name: 'tumourInvolvement',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const Surgery: FunctionComponent<ISurgeryProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Post Neoadjuvant Chemotherapy Ultrasound
                    </Typography>
                    <Condition
                        type={SurgeryDto}
                        condition={hasUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="breastUltrasoundDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedSubformGrid
                            type={SurgeryBreastUltrasound}
                            name="surgeryBreastUltrasounds"
                            columns={surgeryBreastUltrasoundColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={hasNoUltrasound}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="breastUltrasoundSpecify"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <GroupedField
                        name="suspiciousAxillaryNodes"
                        component={BooleanDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={SurgeryDto}
                        condition={hasSuspiciousNodes}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="number"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={hasSurgicalAssessment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="surgicalAssessmentDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={hasNoSurgicalAssessment}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="surgicalAssessmentSpecify"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <GroupedField
                        name="surgeryRequired"
                        component={BooleanDisplay}
                        falseText="No (mastectomy required)"
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Surgical Management
                    </Typography>
                    <GroupedField
                        name="surgeryCompleted"
                        component={BooleanDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={SurgeryDto}
                        condition={hasSurgery}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SurgeryType}
                            name="surgeryTypes"
                            columns={surgeryTypeColumns}
                            disabled
                        />
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                     Sentinel Lymph Node Biopsy
                    </Typography>
                    <Condition
                    type={SurgeryDto}
                    condition={slnbPerformed}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="slnbDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="slnbRemoved"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="nonSlnbRemoved"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="injectionType"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedSubformGrid
                            type={SlnbResult}
                            name="slnbResults"
                            columns={slnbResultsColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={slnbNotPerformed}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="slnb"
                            component={BooleanDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Typography variant="h3" className={classes.subtitle}>
                        Axillary Dissection
                    </Typography>
                    <Condition
                        type={SurgeryDto}
                        condition={dissectionPerformed}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="axillaryDissectionPerformedDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="nodesRemoved"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="positiveNodes"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="axillaryRTPlanned"
                            component={BooleanDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={SurgeryDto}
                        condition={dissectionNotPerformed}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="axillaryDissectionPerformed"
                            component={BooleanDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Surgery;
