import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IframeResizer from 'iframe-resizer-react';
import React, { FunctionComponent } from 'react';

interface IIFramePageProps {
    url: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    iframe: {
        width: '100%',
        border: 'none',
        minWidth: '100%'
    }
}));

const IFramePage: FunctionComponent<IIFramePageProps> = ({ url }) => {
    const { classes } = useStyles();

    return (
        <>
            <IframeResizer
                heightCalculationMethod="lowestElement"
                inPageLinks={false}
                log
                src={url}
                className={classes.iframe}
            />
        </>
    );
};

export default IFramePage;
