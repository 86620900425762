import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField } from '@ngt/forms-medical-review';
import { GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { MedicalHistory as MedicalHistoryDto, MedicalHistoryItem } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import { BasicAlert } from '@ngt/forms-trials';
import FormDates from '../../components/form/FormDates';

interface IMedicalHistoryProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const hasNoMedicalHistory: ICondition<MedicalHistoryDto> = ({ formState: { value } = {} }) => !value?.medicalHistoryItems || value?.medicalHistoryItems?.length === 0;
const hasMedicalHistory: ICondition<MedicalHistoryDto> = ({ formState: { value } = {} }) => !!value?.medicalHistoryItems && value?.medicalHistoryItems?.length > 0;

const conditionSubscription = { value: true };


const medicalHistoryItemColumns: IGroupedSubformGridCell<MedicalHistoryItem>[] = [
    {
        name: 'medicalCondition',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'currentAtRegistration',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'dateOfDiagnosis',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'treatmentOngoing',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const hasChemoTypeOther: ICondition<MedicalHistoryDto> = ({ formState: { value } = {} }) => !!value?.chemotherapyAdministeredTypeOther;

const MedicalHistory: FunctionComponent<IMedicalHistoryProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Relevant Medical History
                    </Typography>
                    <Condition
                        type={MedicalHistoryDto}
                        condition={hasMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={MedicalHistoryItem}
                            name="medicalHistoryItems"
                            columns={medicalHistoryItemColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryDto}
                        condition={hasNoMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Relevant Medical History"
                                description="There is no Relevant Medical History associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Anthracycline Chemotherapy Given
                    </Typography>
                    <GroupedField
                        name="chemotherapyAdministeredType"
                        component={TextDisplay}
                    />
                    <Condition
                        type={MedicalHistoryDto}
                        condition={hasChemoTypeOther}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="chemotherapyAdministeredTypeOther"
                            component={TextDisplay}
                        />
                    </Condition>
                    <GroupedField
                        name="dateOfCommencement"
                        component={DateDisplay}
                    />
                    <GroupedField
                        name="dateOfCompletion"
                        component={DateDisplay}
                    />
                </GroupedFields>
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default MedicalHistory;
