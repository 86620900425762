import React, { FunctionComponent } from 'react';
import {  getParentPropertyPath, IValidationError } from '@ngt/forms';
import { Typography } from '@mui/material';
import { FieldContext, IInputRenderProps, useScopedField } from '@ngt/forms-core';


const subscription = { value: true };

const CoalesceDisplay: FunctionComponent<IInputRenderProps<string, IValidationError> & { ifNullName: string; ifNullAccessor: (value: any) => any; accessor: (value: any) => any; ifNullPrependParentName?: boolean; ifNullTableCell?: boolean; }> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { ...restInputActions } },
    accessor,
    ifNullName,
    ifNullAccessor,
    ifNullPrependParentName,
    ifNullTableCell,
    ...rest
}) => {

    const parentContext = React.useContext(FieldContext);

    const computedIfNullName = React.useMemo(() => {
        if (parentContext?.name && ifNullPrependParentName === true) {
            const parentName = ifNullTableCell === true ? getParentPropertyPath(parentContext?.name) : parentContext?.name;

            if (ifNullName.startsWith('[')) {
                return `${parentName}${ifNullName}`;
            }

            return `${parentName}.${ifNullName}`;
        }

        return ifNullName;
    }, [parentContext?.name, ifNullName, ifNullPrependParentName, ifNullTableCell]);

    const { state: { value: ifNullValue } } = useScopedField<string, IValidationError>(computedIfNullName, subscription, false);

    const calculatedIfNullValue = ifNullAccessor ? ifNullAccessor(ifNullValue) : ifNullValue;
    const calculatedValue = accessor ? accessor(value) : value;

    return (
        <Typography>
            {(calculatedValue ?? calculatedIfNullValue)}
        </Typography>
    )
};

export default CoalesceDisplay;
