import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField, InputOnlyField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { GeneralInformation as GeneralInformationDto, ProtocolDeviation } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

interface IGeneralInformationProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.waivered;
const hasWaiver: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.waivered;

const hasNoDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.protocolDeviations || value?.protocolDeviations?.length === 0;
const hasDeviations: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.protocolDeviations && value?.protocolDeviations?.length > 0;

const hasNoComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !value?.comments;
const hasComments: ICondition<GeneralInformationDto> = ({ formState: { value } = {} }) => !!value?.comments;

const conditionSubscription = { value: true };

const protocoldeviationColumns: IGroupedSubformGridCell<ProtocolDeviation>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'crfName',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'description',
        content: (
            <Input component={TextDisplay} />
        )
    },
    {
        name: 'detailedNote',
        content: (
            <Input component={TextDisplay} />
        )
    }
];

const GeneralInformation: FunctionComponent<IGeneralInformationProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm>
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Protocol Waiver
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasWaiver}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="waiverDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="waiverReason"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoWaiver}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Protocol Waiver"
                                description="There is no protocol waiver associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Protocol Deviations
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        {/*<div className={classes.protocolDeviations}>*/}
                        {/*    <ProtocolDeviationCards />*/}
                        {/*</div>*/}
                        <GroupedSubformGrid
                            type={ProtocolDeviation}
                            name="protocolDeviations"
                            columns={protocoldeviationColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoDeviations}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Protocol Deviations"
                                description="There is no protocol deviations associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Comments and Issues
                    </Typography>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.formPadding}>
                            <InputOnlyField
                                name="comments"
                                component={TextDisplay}
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={GeneralInformationDto}
                        condition={hasNoComments}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Issues or Comments"
                                description="There are no issues or comments associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default GeneralInformation;
